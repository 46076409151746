import { ReactElement, useEffect, useState } from 'react';

import { useLocation } from 'react-router';

import { Navbar } from '../components/Common/Navbar';
import {
  NotInWhiteListModal,
} from '../components/Home/modal';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { pageUrls } from '../constants';

import { SquirrellySquirrels } from '../utils/squirrellySquirrels';
import eth from '../state/eth';

const ViewEmptyWallet = ({
  setShowModal,
}: {
  setShowModal: (arg0: boolean) => void;
}): ReactElement => {
  let { address } = eth.useContainer();
  const navigate = useNavigate();

  const { data: saleStatus } = useQuery(
    ['saleStatus', address],
    async () => {
      let status = 'null';
      try {
        status = await SquirrellySquirrels.getInstance().saleStatus();
      } catch (e) {
        status = 'null';
      }

      return status;
    }
  );

  return (
    <>
      <p
        className="text-white text-2xl md:text-4xl text-center font-semibold font-bangers pt-10"
        style={{ WebkitTextStroke: '0.5px black' }}
      >
        YOU HAVE NO FRENS 💔
      </p>

      <p
        className="text-white text-2xl md:text-4xl text-center font-semibold font-bangers mt-2"
        style={{ WebkitTextStroke: '0.5px black' }}
      >
        ...FOR NOW
      </p>

      <div className="flex justify-center">
        <button
          className="bg-white rounded-full px-5 py-2 mb-2 font-extrabold hover:bg-opacity-90 inline-block cursor-pointer text-sm mt-20"
          style={{ color: '#6A38F5' }}
          onClick={async (): Promise<void> => {
            if (saleStatus === 'sale') {
              navigate(pageUrls.mint);
            } else {
              if (
                await SquirrellySquirrels.getInstance().isAllowListed(address)
              ) {
                navigate(pageUrls.mint);
              } else {
                setShowModal(true);
              }
            }
          }}
        >
          Let's go make some
        </button>
      </div>
    </>
  );
};

const ViewSquirrelsOwned = ({
  walletOfOwner,
}: {
  walletOfOwner: string[];
}): ReactElement => {
  return (
    <>
      <div className="flex flex-row flex-wrap justify-center">
        {walletOfOwner.map((entry) => {
          return (
            <a
              key={entry}
              className="bg-white rounded-3xl px-1 pt-1 pb-5 block my-2 mx-2 md:my-5 md:mx-5 hover:bg-opacity-90"
              href={`https://opensea.io/assets/${process.env.REACT_APP_SQUIRRELLY_SQUIRRELS_ADDRESS}/${entry}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`https://squirrel.mypinata.cloud/ipfs/QmYY1cNScsToYha84j6MZN59sPQcdyxdhchFuDbhuyBh9A/${entry}.png`}
                alt={`${entry}`}
                style={{ width: '210px', height: '212px' }}
                className="border border-black rounded-3xl text-center"
              />
              <p
                className="font-extrabold text-center mt-5"
                style={{ color: '#6A38F5' }}
              >
                Squirrel #{entry}
              </p>
            </a>
          );
        })}
      </div>
    </>
  );
};

export const Account = (): ReactElement => {
  const { address, unlock, signTnc } = eth.useContainer();

  const [showModal, setShowModal] = useState(false);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { data: walletOfOwner } = useQuery(
    ['walletOfOwner', address],
    async () => {
      let walletOfOwner: string[] = [];

      try {
        walletOfOwner = await SquirrellySquirrels.getInstance().walletOfOwner(
          address as string
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }

      const walletOfOwnerModified = walletOfOwner.map((entry) => {
        return entry.toString();
      });

      return walletOfOwnerModified;
    },
    { initialData: [] }
  );

  return (
    <main
      className="flex-grow"
      style={{
        backgroundImage: 'linear-gradient(180deg, #2883D3FF, #7FC3F6FF)',
      }}
    >
      <header className="sticky top-0 z-40 px-5 sm:px-10 pt-3">
        <Navbar />
      </header>
      
      <NotInWhiteListModal display={showModal} setDisplay={setShowModal}/>

      <div className="my-20">
        <div>
          <p
            className="font-black text-4xl md:text-7xl text-center text-white mb-20 z-10 relative font-grotesk"
            style={{ WebkitTextStroke: '1.5px black' }}
          >
            My Collection
          </p>
          <div className="relative z-0">
            <img
              src="/images/account/cloudsRight.png"
              alt="clouds Right"
              className="right-0 absolute"
            />
          </div>
        </div>

        <div className="relative z-10">
          {address === null ? (
            <div className="flex justify-center">
              <button
                className="bg-white rounded-full px-5 py-2 mb-2 font-extrabold hover:bg-opacity-90 inline-block cursor-pointer text-sm mt-20"
                style={{ color: '#6A38F5' }}
                onClick={async (): Promise<void> => {
                  localStorage.removeItem('chosenWallet');
                  await unlock();

                  await signTnc();
                }}
              >
                Connect Wallet to View Collection
              </button>
            </div>
          ) : walletOfOwner?.length === 0 ? (
            <ViewEmptyWallet setShowModal={setShowModal} />
          ) : (
            <ViewSquirrelsOwned walletOfOwner={walletOfOwner as string[]} />
          )}
        </div>

        <div className="relative z-0">
          <img
            src="/images/account/cloudsLeft.png"
            alt="clouds Left"
            className="left-0 bottom-0 absolute"
          />
        </div>
      </div>
    </main>
  );
};

export default Account;
