import { ReactElement, useEffect, useState } from 'react';

import { FaDiscord } from 'react-icons/fa';
import { BsTwitter } from 'react-icons/bs';
import { SiGitbook } from 'react-icons/si';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';

import { contact, screenWidthBreakpoint, pageUrls } from '../../constants';

import { useWindowSize } from '../../hooks/useWindowSize';

import eth from '../../state/eth';
import { useNavigate } from 'react-router-dom';

export const Navbar = ({ className }: { className?: string }): ReactElement => {
  const { screenWidth } = useWindowSize();
  const navigate = useNavigate();

  let { address, unlock, signTnc } = eth.useContainer();

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    (async (): Promise<void> => {
      const chosenWallet = localStorage.getItem('chosenWallet');

      if (chosenWallet) {
        await unlock();

        await signTnc();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateLocal = (event: any, url: string): void => {
    event.preventDefault();

    navigate(url);
    setMenuOpen(false);
  };

  return (
    <div className={`${className}`}>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row space-x-3">
          <div>
            <a
              href={contact.twitterLink}
              className="icon"
              style={{ padding: '8px' }}
              target="_blank"
              rel="noreferrer"
            >
              <BsTwitter size={18} />
            </a>
          </div>
          <div>
            <a
              href={contact.discordLink}
              className="icon"
              target="_blank"
              rel="noreferrer"
            >
              <FaDiscord size={20} />
            </a>
          </div>
          <div>
            <a
              href={contact.openseaLink}
              className=""
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/opensea.svg"
                alt="gitbook"
                className="rounded-full hover:opacity-90"
                style={{ backgroundColor: '#6a38f5', height: '34px' }}
              />
            </a>
          </div>
        </div>
        <div className="flex flex-row">
          <div
            className="bg-white rounded-full px-5 py-2 mb-2 font-extrabold hover:bg-opacity-90 inline-block cursor-pointer text-sm"
            style={{ color: '#6A38F5' }}
            onClick={async (): Promise<void> => {
              localStorage.removeItem('chosenWallet');
              await unlock();

              await signTnc();
            }}
          >
            {address === null ? (
              <>
                {screenWidth < screenWidthBreakpoint.sm ? (
                  <>Wallet</>
                ) : (
                  <>Connect Wallet</>
                )}
              </>
            ) : (
              <>
                {screenWidth < screenWidthBreakpoint.sm ? (
                  <>
                    {address.substr(0, 4) +
                      '...' +
                      address.slice(address.length - 3)}
                  </>
                ) : (
                  <>
                    {address.substr(0, 6) +
                      '...' +
                      address.slice(address.length - 4)}
                  </>
                )}
              </>
            )}
          </div>

          <div className="ml-5">
            <button
              onClick={(): void => {
                setMenuOpen(!menuOpen);
              }}
            >
              {menuOpen ? (
                <IoMdClose size={40} className="text-white" />
              ) : (
                <GiHamburgerMenu size={40} className="text-white" />
              )}
            </button>
          </div>
        </div>
      </div>

      {menuOpen && (
        <div
          style={{ backgroundColor: '#A693DF', right: '2.5rem' }}
          className="py-5 rounded-2xl w-8/12 sm:w-3/12 float-right absolute"
        >
          {navbarLinks.map((entry) => {
            return (
              <div
                key={entry.label}
                className="font-bangers text-4xl text-center text-squirrellyYellow my-3"
              >
                <a
                  href={entry.url}
                  onClick={(e): void => {
                    navigateLocal(e, entry.url);
                  }}
                  className="w-full block"
                >
                  {entry.label}
                </a>

                {entry.children?.map((entry) => {
                  return (
                    <div
                      key={entry.label}
                      className="font-bangers text-xl text-center text-squirrellyYellow mt-3"
                    >
                      <a
                        href={entry.url}
                        onClick={(e): void => {
                          navigateLocal(e, entry.url);
                        }}
                        className="w-full block"
                      >
                        {entry.label}
                      </a>
                    </div>
                  );
                })}
              </div>
            );
          })}
          <p className="font-bangers text-4xl text-center text-squirrellyYellow my-3">
            🌰
          </p>
        </div>
      )}
    </div>
  );
};

export default Navbar;

const navbarLinks: {
  label: string;
  url: string;
  children?: {
    label: string;
    url: string;
  }[];
}[] = [
  {
    label: 'HOME',
    url: pageUrls.home,
    children: [
      { label: 'MOONMAP', url: '/#moonmap' },
      { label: 'FAQ', url: '/#faq' },
      { label: 'ARTISTS', url: '/#artists' },
    ],
  },
  { label: 'STORY', url: pageUrls.story },
  { label: 'GENERATOR', url: pageUrls.generator },
  { label: 'MY ACCOUNT', url: pageUrls.dropAnimation },
  { label: 'PROVENANCE', url: pageUrls.provenance },
  { label: 'TERMS', url: pageUrls.tnc },
];
