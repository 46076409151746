import { ReactElement } from 'react';
import { pageUrls } from '../../constants';

import { useNavigate } from 'react-router-dom';

export const Welcome = (): ReactElement => {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col md:flex-row w-11/12 md:w-11/12 justify-center mx-auto"
      style={{ marginTop: '-5%' }}
    >
      <div className="mx-auto md:mx-auto md:w-7/12">
        <p className="text-center font-grotesk font-black text-squirrellyYellow text-3xl md:text-5xl lg:text-6xl mb-20">
          WELCOME TO THE SQUIRRELVERSE
        </p>

        <div className="md:mx-10">
          <p className="mb-5">
            <span className="text-white font-inter font-medium">
              10,000 programmatically generated Squirrels built a utopia in the
              Metaverse! Each Squirrel is unique and 1 of over 450 billion
              possible combinations
            </span>
            ✨
          </p>
          <p className="text-white font-inter font-medium mb-5">
            Each Squirrel is a cuddly key to a community of crypto enthusiasts
            sharing their love and knowledge of all things NFT and DeFi.
          </p>
          <p className="text-white font-inter font-medium">
            Squirrelly Squirrels are a project by{' '}
            <a
              href="https://www.squirrelwallet.com/"
              className="underline font-bold"
              target="_blank"
              rel="noreferrer"
            >
              Squirrel Wallet
            </a>
            (launching summer 2022). They are ERC-721 tokens hosted on IPFS and
            each one is randomly generated in an{' '}
            <a
              href="https://app.gitbook.com/o/-Mhnl_M1zDoqNPCqNw56/s/-MhnleJ_usghAUyOavjV/products/squirrelly-squirrels/provably-fair-nft-distribution"
              className="underline font-bold"
              target="_blank"
              rel="noreferrer"
            >
              auditable, fair process
            </a>
            .
          </p>
        </div>

        <div className="flex justify-center items-center mt-20">
          <a
            href={pageUrls.story}
            onClick={async (e): Promise<void> => {
              e.preventDefault();

              navigate(pageUrls.story);
            }}
            className="rounded-full px-10 py-3 self-center bg-white font-extrabold text-squirrellyPurple hover:bg-opacity-90"
          >
            Story
          </a>
        </div>
      </div>

      <div className="mx-auto md:mx-0 md:w-4/12 welcome-unicorn">
        <img
          src="/images/unicorn.png"
          className="md:float-right"
          alt="unicorn"
        />
      </div>
    </div>
  );
};

export default Welcome;
