import { ReactElement } from 'react';

const RoadmapContent = ({
  className,
}: {
  className?: string;
}): ReactElement => {
  return (
    <div className={`${className}`}>
      {roadmap.map((entry, index) => {
        return (
          <div className="flex flex-row bg-white mb-5 rounded-3xl p-3 relative z-30" key={index}>
            <div
              style={{
                minWidth: '140px',
                minHeight: '125px',
                backgroundColor: '#4E26BE1A',
              }}
              className="rounded-3xl mr-5 flex justify-center items-center"
            >
              {entry.percentage}
            </div>

            <div className="flex-grow justify-center flex flex-col">
              {entry.title}
              {entry.message}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const Roadmap = (): ReactElement => {
  return (
    <div style={{ backgroundColor: 'rgba(255, 227, 81, 1)' }}>
      <div style={{ marginTop: '-24%' }}>
        <p className="text-center font-grotesk font-black text-squirrellyPurple text-5xl md:text-7xl mb-6" id="moonmap">
          MOONMAP
        </p>
        <p className="text-center text-squirrellyPurple text-lg pb-10 mx-auto font-black px-2 md:px-0 md:text-xl md:w-6/12">
          <span className="opacity-70">
            The sooner you become a Squirrelly Squirrel owner, the more perks
            you're eligible for. Read more about our roadmap{' '}
          </span>
          <a
            href="https://app.gitbook.com/o/-Mhnl_M1zDoqNPCqNw56/s/-MhnleJ_usghAUyOavjV/c/1fGxwOJ5hpLS9f0lUuBv/products/squirrelly-squirrels/squirrelly-roadmap"
            target="_blank"
            rel="noreferrer"
            className="font-black underline opacity-100"
          >
            here
          </a>
        </p>

        <RoadmapContent className="w-11/12 md:w-8/12 lg:w-6/12 mx-auto" />
      </div>
    </div>
  );
};

export const roadmap: {
  percentage: ReactElement;
  message: ReactElement;
  title: ReactElement;
}[] = [
  {
    percentage: (
      <p className="text-center font-black text-xl" style={{ color: '#6A38F5' }}>
        <span className="font-grotesk text-5xl">0</span>%
      </p>
    ),
    message: (
      <p className="text-squirrellyPurple font-medium line-through">
        Admins will be giving active, engaging members a chance to participate
        in the Squirrelly Squirrel pre-sale. Show off your nutty side to earn 1
        of 1,000 whitelist spots!
      </p>
    ),
    title: (
      <p className="text-squirrellyPurple font-extrabold mb-2 line-through">
        Join our Discord today and be an O.G in the Squirrel community!
      </p>
    ),
  },
  {
    percentage: (
      <p className="text-center font-black text-xl" style={{ color: '#6A38F5' }}>
        <span className="font-grotesk text-5xl">25</span>%
      </p>
    ),
    message: (
      <p className="text-squirrellyPurple font-medium">
        Authenticate your Squirrel and become eligible for gifts worth up to
        $10,000 when our $NUTS token launches. Chances of winning will be
        weighted by Squirrel rarity.
      </p>
    ),
    title: (
      <p className="text-squirrellyPurple font-extrabold mb-2">
        This is for our day ones, because you loyal.
      </p>
    ),
  },
  {
    percentage: (
      <p className="text-center font-black text-xl" style={{ color: '#6A38F5' }}>
        <span className="font-grotesk text-5xl">50</span>%
      </p>
    ),
    message: (
      <p className="text-squirrellyPurple font-medium">
        Get another chance to win a special $NUTS Package so you can treat
        yo’self. Use the $NUTS however you want! Buy a real squirrel idk.
      </p>
    ),
    title: (
      <p className="text-squirrellyPurple font-extrabold mb-2">
        Ultra exclusive Discord group for Squirrelly Squirrels holders.
      </p>
    ),
  },
  {
    percentage: (
      <p className="text-center font-black text-xl" style={{ color: '#6A38F5' }}>
        <span className="font-grotesk text-5xl">100</span>%
      </p>
    ),
    message: (
      <p className="text-squirrellyPurple font-medium">
        Every original buyer will get their $NUTS, with a few lucky Squirrels
        taking home our 2 Jackpots. Did we mention we’re making a wallet?
        Squirrels get beta access.
      </p>
    ),
    title: (
      <p className="text-squirrellyPurple font-extrabold mb-2">
        Get ready for the retroactive $NUTS airdrop.
      </p>
    ),
  },
  {
    percentage: (
      <p className="text-center font-grotesk text-2xl" style={{ color: '#6A38F5' }}>
        FUTURE
      </p>
    ),
    message: (
      <p className="text-squirrellyPurple font-medium">
        We can’t wait to serve you Gen2 evolution opportunities, minigames for
        your Squirrels, merch, and digitally authenticated NFT frames. Let’s get
        fancy.
      </p>
    ),
    title: (
      <p className="mb-2">
        <span className="text-squirrellyPurple font-extrabold">
          We in the game
        </span>{' '}
        👾
      </p>
    ),
  },
];

export default Roadmap;
