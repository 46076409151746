import { ReactElement, useState, useEffect } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';

import { useQuery } from 'react-query';

const ImageCreator = ({
  layerNumber,
  listToIterate,
  imageLocation,
  type,
}: {
  layerNumber: number;
  listToIterate: string[];
  imageLocation: string;
  type: 'backgrounds' | 'skins' | 'eyes'| 'mouths' | 'clothes' | 'other';
}): ReactElement => {
  // keep default same as the first element of generator page
  const backgroundDefault =
    '/images/generator/layers/Backgrounds/common_blue.png';
  const skinDefault = '/images/generator/layers/Skins/brown.png';
  const eyesDefault = '/images/generator/layers/Eyes/plain.png';
  const mouthDefault = '/images/generator/layers/Mouths/normal.png';

  const { screenWidth } = useWindowSize();

  let imageHeight = screenWidth < 400 ? '250px' : '350px';
  let imageWidth = screenWidth < 400 ? '250px' : '350px';

  const imageClasses = 'absolute rounded-xl border-4 border-black';

  return (
    <div className="" style={{ height: imageHeight, width: imageWidth }}>
      {/* Background */}
      {type === 'backgrounds' ? (
        <img
          src={`${imageLocation}/${listToIterate[layerNumber]}.png`}
          alt="backgrounds"
          className={`${imageClasses}`}
          style={{
            height: imageHeight,
            width: imageWidth,
          }}
        />
      ) : (
        <img
          src={backgroundDefault}
          alt="backgrounds"
          className={`${imageClasses}`}
          style={{
            height: imageHeight,
            width: imageWidth,
          }}
        />
      )}
      {/* Skin */}
      {type === 'skins' ? (
        <img
          src={`${imageLocation}/${listToIterate[layerNumber]}.png`}
          alt="skins"
          className={`${imageClasses}`}
          style={{
            height: imageHeight,
            width: imageWidth,
          }}
        />
      ) : (
        <img
          src={skinDefault}
          alt="skins"
          className={`${imageClasses}`}
          style={{
            height: imageHeight,
            width: imageWidth,
          }}
        />
      )}
      {/* Eyes */}
      {type === 'eyes' ? (
        <img
          src={`${imageLocation}/${listToIterate[layerNumber]}.png`}
          alt="eyes"
          className={`${imageClasses}`}
          style={{
            height: imageHeight,
            width: imageWidth,
          }}
        />
      ) : (
        <img
          src={eyesDefault}
          alt="eyes"
          className={`${imageClasses}`}
          style={{
            height: imageHeight,
            width: imageWidth,
          }}
        />
      )}
      {/* Clothes */}
      {type === 'clothes' && (
        <img
          src={`${imageLocation}/${listToIterate[layerNumber]}.png`}
          alt="clothes"
          className={`${imageClasses}`}
          style={{
            height: imageHeight,
            width: imageWidth,
          }}
        />
      )}

      {/* Mouths */}
      {type === 'mouths' ? (
        <img
          src={`${imageLocation}/${listToIterate[layerNumber]}.png`}
          alt="mouth"
          className={`${imageClasses}`}
          style={{
            height: imageHeight,
            width: imageWidth,
          }}
        />
      ) : (
        <img
          src={mouthDefault}
          alt="mouth"
          className={`${imageClasses}`}
          style={{
            height: imageHeight,
            width: imageWidth,
          }}
        />
      )}

      {type === 'other' && (
        <img
          src={`${imageLocation}/${listToIterate[layerNumber]}.png`}
          alt="other"
          className={`${imageClasses}`}
          style={{
            height: imageHeight,
            width: imageWidth,
          }}
        />
      )}
    </div>
  );
};

export const LayerPicker = ({
  layerNumber,
  setLayerNumber,
  listToIterate,
  imageLocation,
  type,
  accessoryBeingModified,
}: {
  layerNumber: number;
  setLayerNumber: React.Dispatch<React.SetStateAction<number>>;
  listToIterate: string[];
  imageLocation: string;
  type: 'backgrounds' | 'skins' | 'eyes' | 'mouths' | 'clothes' | 'other';
  accessoryBeingModified: string;
}): ReactElement => {
  const { data: rarityData, isLoading: rarityLoading } = useQuery(
    'rarity',
    async () => {
      const data = await fetch('/data/generator/rarity.json', {
        method: 'GET',
        redirect: 'follow',
      });
      return await data.json();
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  let [rarityPercentage, setRarityPercentage] = useState<Number>(0);

  useEffect(() => {
    if (rarityLoading) {
      setRarityPercentage(0);
    } else {
      setRarityPercentage(
        rarityData[accessoryBeingModified][listToIterate[layerNumber]].rarity *
          100
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rarityLoading, layerNumber, listToIterate, accessoryBeingModified]);

  return (
    <div className="flex flex-row">
      <div className="rounded-xl bg-white p-2 inline-block mx-auto">
        <ImageCreator
          layerNumber={layerNumber}
          listToIterate={listToIterate}
          imageLocation={imageLocation}
          type={type}
        />

        <div className="flex flex-row py-3 justify-between px-1">
          <button
            className=""
            onClick={(): void => {
              if (layerNumber <= 0) {
                setLayerNumber(listToIterate.length - 1);
              } else {
                setLayerNumber(layerNumber - 1);
              }
            }}
          >
            <img src="/images/generator/leftArrow.png" alt="left arrow" />
          </button>

          <div className="flex flex-col">
            <p className="text-center text-squirrellyPurple font-bangers text-3xl">
              {listToIterate[layerNumber][0].toUpperCase()}
              {listToIterate[layerNumber].split('_').join(' ').slice(1)}{' '}
            </p>
            <div
              className="stars mx-auto"
              style={{ ['--w' as any]: `${rarityPercentage}%` }}
            ></div>
          </div>

          <button
            className=""
            onClick={(): void => {
              if (layerNumber + 1 === listToIterate.length) {
                setLayerNumber(0);
              } else {
                setLayerNumber(layerNumber + 1);
              }
            }}
          >
            <img src="/images/generator/rightArrow.png" alt="right arrow" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default LayerPicker;
