import eth from './eth';

import type { ReactElement } from 'react';

// Global state provider
export const GlobalProvider = ({children}: {children: ReactElement[]}): ReactElement  => {
  return (
    <eth.Provider>{children}</eth.Provider>
  )
}

// Export individual providers
export { eth };