import { useState, useEffect } from 'react';
import { isBrowser } from '../utils/isBrowser';

export const useWindowSize = (): { screenWidth: number; screenHeight: number } => {
  const [windowSize, setWindowSize] = useState({
    screenWidth: 1000,
    screenHeight: 1000
  });

  useEffect((): any => {
    if (isBrowser()) {
      const handleResize = (): void => {
        setWindowSize({
          screenWidth: window.innerWidth,
          screenHeight: window.innerHeight
        });
      };

      window.addEventListener('resize', handleResize);

      handleResize();

      return (): void => window.removeEventListener('resize', handleResize);
    }
    return null;
  }, []);

  return windowSize;
};

export default useWindowSize;