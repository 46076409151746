import { ReactElement, useState } from 'react';

import { screenWidthBreakpoint, pageUrls } from '../../constants';

import { useWindowSize } from '../../hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';

interface CardData {
  image: string;
  title: string;
  data: ReactElement;
}

export const SquirrelCard = ({
  cardData,
  id,
}: {
  cardData: CardData[];
  id?: string;
}): ReactElement => {
  const { screenWidth } = useWindowSize();

  const [currentCard, setCurrentCard] = useState(0);

  const prevCard = (): void => {
    if (currentCard === 0) {
      setCurrentCard(cardData.length - 1);
    } else {
      setCurrentCard(currentCard - 1);
    }
  };

  const nextCard = (): void => {
    if (currentCard + 1 === cardData.length) {
      setCurrentCard(0);
    } else {
      setCurrentCard(currentCard + 1);
    }
  };

  return (
    <div
      className="p-5 border-2 border-black mx-auto bg-white"
      style={{
        width: `${screenWidth >= screenWidthBreakpoint.sm ? '456px' : '360px'}`,
        height: '1000px',
      }}
      id={id}
    >
      <img
        src={cardData[currentCard].image}
        alt={cardData[currentCard].title}
        className="mx-auto border-4 border-black"
      />

      <div className="flex flex-row justify-between items-center lg:mx-8 my-4">
        <button onClick={prevCard}>
          <img src="/images/story/leftArrow.png" alt="leftArrow" />
        </button>
        <p className="font-bangers text-squirrellyPurple text-4xl text-center flex-grow">
          {cardData[currentCard].title}
        </p>
        <button onClick={nextCard}>
          <img src="/images/story/rightArrow.png" alt="rightArrow" />
        </button>
      </div>

      <div className="text-squirrellyPurple lg:mx-8 font-semibold">
        {cardData[currentCard].data}
      </div>
    </div>
  );
};

export const FamousSquirrels = (): ReactElement => {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-10 xl:space-x-24 justify-center"
      style={{ minHeight: '860px' }}
    >
      <a
        className="block lg:hidden mx-auto"
        href={`${pageUrls.story}#1`}
        onClick={(e): void => {
          e.preventDefault();

          navigate(`${pageUrls.story}#1`);
        }}
      >
        <img src="/images/story/mobile/stay_hidden.png" alt="Stay hidden" />
      </a>

      <a
        className="block lg:hidden mx-auto"
        href={`${pageUrls.story}#2`}
        onClick={(e): void => {
          e.preventDefault();

          navigate(`${pageUrls.story}#2`);
        }}
      >
        <img src="/images/story/mobile/take_over.png" alt="Take over" />
      </a>

      <div>
        <div className="hidden lg:block">
          <img
            src="/images/story/desktop/stay_hidden.png"
            alt="STAY SECRET STAY HIDDEN"
            className="mx-auto mb-5"
            style={{ height: '100px' }}
          />
          <img
            src="/images/story/blue-arrow.png"
            alt="arrow"
            className="animate-bounce mx-auto mb-20"
          />
        </div>

        <SquirrelCard cardData={firstCardData} id="1" />
      </div>

      <div>
        <div className="hidden lg:block">
          <img
            src="/images/story/desktop/take_over.png"
            alt="STAY SECRET STAY HIDDEN"
            className="mx-auto mb-5"
            style={{ height: '100px' }}
          />
          <img
            src="/images/story/red-arrow.png"
            alt="arrow"
            className="animate-bounce mx-auto mb-20"
          />
        </div>

        <SquirrelCard cardData={secondCardData} id="2" />
      </div>
    </div>
  );
};

export default FamousSquirrels;

const firstCardData: CardData[] = [
  {
    image: '/images/story/squirrels/p-nut.png',
    title: 'Ser Chester P. Nut',
    data: (
      <>
        <p className="mb-3">
          Ser Chester P. Nut is a whimsical squirrel - you could even say he’s a
          little willy wonky. After all, he was nutty enough to come up with the
          idea for the Squirrelverse to begin with.
        </p>
        <p className="mb-3">
          His knack for gathering nuts made him very wealthy, but P. Nut had a
          bigger vision. He believed that all Squirrels could prosper if they
          hid their nuts from humans, so he decided to spend it all to build a
          safe haven. It wasn’t long before the Squirrels chose him to be their
          leader.
        </p>
        <p>
          Strangely, no matter what he put into the Squirrelverse the nuts came
          rolling back. They say Fortune favors the bold, and none are bolder
          than the Squirrel King.
        </p>
      </>
    ),
  },
  {
    image: '/images/story/squirrels/yuna.png',
    title: 'Yuna Skwerl',
    data: (
      <>
        <p className="mb-3">
          She's a beam of light in the Squirrelverse, Squirrel idol, and
          influencer. Despite being the firstborn of a famous Squirrel
          scientist, Yuna prefers the beautiful (artificial) outdoors to the
          sterile halls of her father's lab.
        </p>
        <p>
          She loves to go looking rainbows so she can follow it to see where it
          leads. Every time one appears, her eyes light up with glee and
          excitement for adventure.
        </p>
      </>
    ),
  },
  {
    image: '/images/story/squirrels/spinner.png',
    title: 'Spinner',
    data: (
      <>
        <p className="mb-3">
          Spinner lives for the thrill. Anywhere they have to go, they take
          their jetpack for a joyride. They love the feeling of zipping around
          in the Squirrelverse, wind in their tail. So much so, they often end
          up somewhere far away and a little lost! Thankfully the Squirrelverse
          has an artificial sky, and every night the skies light up to lead you
          back to the city center.
        </p>
        <p>
          With a love for speed and a terrible sense of direction, this rowdy
          squirrel is one that’s hard to catch.
        </p>
      </>
    ),
  },
  {
    image: '/images/story/squirrels/forage.png',
    title: 'Forage',
    data: (
      <>
        <p>
          Good natured Forage loves a hearty meal followed by a nice nap. Their
          sweet charm and good humor makes them one of the best-loved amongst
          Squirrels. For Forage, the Squirrelverse is home and life here is
          everything they've ever wanted - who needs anything more?
        </p>
      </>
    ),
  },
  {
    image: '/images/story/squirrels/flyer.png',
    title: 'Flyer',
    data: (
      <>
        <p>
          Flyer, despite his rather descriptive name, is more an aircraft
          marshaller than a flyer. He’s loved airplanes from the time he was a
          young squirrel, but is terrified of heights. He's therefore made a
          career for himself guiding Squirrel pilots to land their aircrafts,
          and as air marshals go, Flyer is the absolute top gun. Some pilots are
          a little nutty with their landings sometimes, but Flyer says he
          doesn't mind. "It keeps me on my toes, ha..haha," he'll say, with a
          noticeable tremble in his voice.
        </p>
      </>
    ),
  },
  {
    image: '/images/story/squirrels/sly.png',
    title: 'Sly',
    data: (
      <>
        <p>
          A highly skilled ninja squirrel that excels at the art of nut foraging
          and information gathering. They wear all black to sneak around in the
          shadows. Their wherabouts are often unknown, and that's how they'd
          like to keep things. Agile and fearless, Sly travels across the
          Squirrelverse and surface world with ease and can tell you
          definitively that they prefer the Squirrelverse. This Metaverse thing
          the humans are talking about though, could be a different story. Sly
          could possibly get with the Metaverse.
        </p>
      </>
    ),
  },
];

const secondCardData: CardData[] = [
  {
    image: '/images/story/squirrels/frank-n-skwerl.png',
    title: 'Dr. Frank N. Skwerl',
    data: (
      <>
        <p className="mb-3">
          Dr. Frank N. Skwerl is the Squirrellies' maddest, raddest, scientist.
          As the mind behind much of their technology, Dr. Skwerl knows better
          than most just how easily they could take over the surface. Just how
          much they deserved to take the surface. But he can’t do it alone and
          the Squirrels seem happy with their utopia. Why did he have to make it
          so perfect?
        </p>
        <p>
          He’s been watching the silly humans for years, and recently they seem
          obsessed with a new world on the internet, the Metaverse. If his
          fellow Squirrels won’t take the surface world, perhaps they could take
          the Metaverse instead...
        </p>
      </>
    ),
  },
  {
    image: '/images/story/squirrels/scratch.png',
    title: 'Scratch',
    data: (
      <>
        <p>
          For Scratch, it’s rock and roll luv and his dream is to share his art
          with the world. Inspired by many of the kings of rock and roll,
          Scratch spends his days making music with absolutely anything he can
          find, and the Squirrellies are his biggest fans. During one of his
          concerts, he was shredding on the guitar and accidentally produced
          sound waves big enough to create a mass amount of energy. The
          Squirrels discovered that they could use this energy for something
          bigger…
        </p>
      </>
    ),
  },
  {
    image: '/images/story/squirrels/trekker.png',
    title: 'Trekker',
    data: (
      <>
        <p className="mb-3">
          Trekker is out of this world. They’re one of the few Squirrels who
          have ventured in and out of the Squirrelverse to the human world.
          Trekker does it to observe the night sky. They dream of beings more
          advanced than Squirrels visiting from places far away. One day they
          overheard humans talking about a "metaverse". What's this, a new
          galaxy to explore?
        </p>
        <p>
          The crazy scientist was the only one who would listen and promised
          they would make contact with whoever was on the other side. Trekker
          can't wait to see what's in store for the Squirrels and what awesome
          friends they'll make in this new world.
        </p>
      </>
    ),
  },
  {
    image: '/images/story/squirrels/astro.png',
    title: 'Astro Nut',
    data: (
      <>
        <p className="mb-3">
          One small step for Squirrelly Squirrels, one giant leap for
          rodentkind! Astro makes regular trips to outer space to gather all
          sorts of intel for the Squirrelverse - weather patterns (earth and
          space), all sorts of interesting scientific discoveries, crypto news
          and government secrets
        </p>
        <p>
          Astro was never that attached to the hidden Squirrelverse or the
          surface world, but enamored with the idea of this new digital world.
          It's their mission to venture where no Squirrel has been before - out
          there in the Metaverse, what new galaxies are there to explore?
        </p>
      </>
    ),
  },
  {
    image: '/images/story/squirrels/redbeard.png',
    title: 'Redbeard',
    data: (
      <>
        <p>
          Redbeard roams the Squirrelverse looking far and wide for the finest
          treasures. Like any good fantastical treasure hoarder, he hides his
          stash, but these days he does this behind puzzles, encrypted keys,
          secret codes. He finds riddles and puzzles fun, and the interaction
          with adventurous Squirrels is an added bonus - it's no fun to dragon
          alone! He rewards those who succeed in solving his puzzle, but any one
          who dares step near his treasures uninvited is sure to meet their end
          in a fiery feud.
        </p>
      </>
    ),
  },
  {
    image: '/images/story/squirrels/rambo.png',
    title: 'Rambo',
    data: (
      <>
        <p>
          Rambo is a rambunctious pilot, a lone wolf who makes his own rules in
          the Squirrelverse. His mischievous ways often end him up in dangerous
          situations, but at least he has stories to tell! His expert guerilla
          tactics have protected the Squirrels from human discovery on many
          occasions. Somewhere deep inside though, Rambo longs to explore worlds
          outside the Squirrelverse. Recently, there've been whispers of a
          Metaverse beyond...will he answer this call to adventure?
        </p>
      </>
    ),
  },
];
