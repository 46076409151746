import { ethers } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';

import squirrellySquirrelsJson from '../contracts/SquirrellySquirrels.json';
import { getProof as getProofFirebase } from './firebase';

const SQUIRRELLY_SQUIRRELS_ADDRESS = process.env.REACT_APP_SQUIRRELLY_SQUIRRELS_ADDRESS ?? '0xa68569ccDCE1E924DdA43E2C3bB80E7B5B810bb9';

export class SquirrellySquirrels {
    private static instance: SquirrellySquirrels;
    private static provider: Web3Provider | null = null;
    private static currentAddress: string|null = null;
    private static proof: string[];

    public contract: ethers.Contract;

    private constructor(provider: Web3Provider | null) {
      this.contract = new ethers.Contract(
        SQUIRRELLY_SQUIRRELS_ADDRESS,
        squirrellySquirrelsJson,
        provider?.getSigner()
      );
    }

    public static getInstance(): SquirrellySquirrels {
      if (!SquirrellySquirrels.instance || SquirrellySquirrels.provider === null) {
        throw new SquirrellySquirrelsError('SquirrellySquirrels instance DNE');
      }

      return SquirrellySquirrels.instance;
    }

    public static setInstance(provider: Web3Provider | null): void {
      SquirrellySquirrels.instance = new SquirrellySquirrels(provider);

      SquirrellySquirrels.provider = provider;
    }

    public async sale(numberOfTokens: 1 | 3 | 5): Promise<any> {
      if (numberOfTokens === 1) {
        return await this.contract.mint(numberOfTokens, {
          value: ethers.utils.parseEther(ethers.utils.formatEther(await this.contract.NFT_1_PRICE()))
        })
      } else if (numberOfTokens === 3) {
        return await this.contract.mint(numberOfTokens, {
          value: ethers.utils.parseEther(ethers.utils.formatEther(await this.contract.NFT_3_PRICE()))
        })
      } else if (numberOfTokens === 5) {
        return await this.contract.mint(numberOfTokens, {
          value: ethers.utils.parseEther(ethers.utils.formatEther(await this.contract.NFT_5_PRICE()))
        })
      }
    }

    public async preSale(address: string | null, numberOfTokens: 1 | 3 | 5): Promise<any> {
      let proof = await this.getProof(address as string)

      if (numberOfTokens === 1) {
        return await this.contract.mintPresale(proof, numberOfTokens, {
          value: ethers.utils.parseEther(ethers.utils.formatEther(await this.contract.NFT_1_PRICE())),
        })
      } else if (numberOfTokens === 3) {
        return await this.contract.mintPresale(proof, numberOfTokens, {
          value: ethers.utils.parseEther(ethers.utils.formatEther(await this.contract.NFT_3_PRICE()))
        })
      } else if (numberOfTokens === 5) {
        return await this.contract.mintPresale(proof, numberOfTokens, {
          value: ethers.utils.parseEther(ethers.utils.formatEther(await this.contract.NFT_5_PRICE()))
        })
      }
    }

    public async getProof(address: string): Promise<string[]> {
      if (address.toLowerCase() !== SquirrellySquirrels.currentAddress) {
        SquirrellySquirrels.currentAddress = address.toLowerCase();
        SquirrellySquirrels.proof = await getProofFirebase(address as string);
      }
        
      return SquirrellySquirrels.proof;
    }

    public async isAllowListed(address: string | null): Promise<boolean> {
      const proof = await this.getProof(address as string);

      try {
        return await this.contract.isAllowListed(proof, address);
      } catch (err) {
        return false;
      }
    }

    public async saleStatus(): Promise<'sale' | 'presale' | 'null'> {
      let status: 'null' | 'sale' | 'presale' = 'null';

      if (await this.contract.saleIsActive() === true) {
        status = 'sale';
      } else if (await this.contract.presaleIsActive() === true) {
        status = 'presale'
      }

      return status;
    }

    public async totalSupply(): Promise<number> {
      return await this.contract.totalSupply();
    }

    public async walletOfOwner(address: string): Promise<string[]> {
      return await this.contract.walletOfOwner(address);
    }

    public async revealed(): Promise<boolean> {
      return await this.contract.revealed();
    }
}

class SquirrellySquirrelsError extends Error {}