import { ReactElement, useState } from 'react';

import isEmail from 'validator/lib/isEmail';
import { setEmailFooter } from '../../../utils/firebase';

import { BsArrowRight } from 'react-icons/bs';
import { ImCheckmark } from 'react-icons/im';

export const NotInWhiteListModal = ({
  display,
  setDisplay,
}: {
  display: boolean;
  setDisplay: (arg0: boolean) => void;
}): ReactElement => {
  const [formEmail, setFormEmail] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <div className="static">
      {display && (
        <div className="absolute w-full bg-gray-600 opacity-30 z-10 h-screen"></div>
      )}

      <div
        id="notInWhiteListModal"
        aria-hidden="true"
        className={`${
          !display && 'hidden'
        } overflow-x-hidden overflow-y-auto fixed w-full z-40 modalTop`}
      >
        <div className="relative w-full px-2 md:w-1/2 md:px-0 h-full md:h-auto mx-auto">
          <div className="bg-white rounded-2xl shadow relative dark:bg-gray-700 px-5 pt-5 pb-10">
            <div className="flex justify-end p-2">
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="not-in-whitelist-modal"
                onClick={(): void => {
                  setDisplay(false);
                }}
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>

            <img
              src="/images/notInWhitelistModalImage.png"
              alt="not in whitelist modal"
              className="mx-auto mb-5"
              height="150px"
              width="150px"
            />

            <p className="text-squirrellyPurple font-grotesk text-4xl text-center pb-5 font-black">
              AW NUTS, YOU'RE NOT ON THE WHITELIST
            </p>

            <p className="px-12 text-center text-squirrellyPurple mt-5">
              <span className="text-opacity-90">Come back</span>{' '}
              <span className="font-black">Feb 10, 2022, 12 pm Eastern</span>{' '}
              <span className="text-opacity-90">
                for the public sale! We can’t wait to see you in the
                Squirrelverse!
              </span>
            </p>

            <div className="pt-5">
              {formSubmitted ? (
                <div className="flex justify-center text-white">
                  <div
                    className="rounded-full p-2"
                    style={{ backgroundColor: '#50D300' }}
                  >
                    <ImCheckmark size={40} />
                  </div>
                </div>
              ) : (
                <form
                  onSubmit={(e: any): void => {
                    e.preventDefault();
                  }}
                >
                  <div className="w-11/12 sm:w-10/12 lg:w-8/12 mx-auto mb-5">
                    <p
                      className={`text-red-600 ${
                        (isEmail(formEmail) || formEmail === '') && 'invisible'
                      }`}
                    >
                      Invalid Email
                    </p>
                    <div className="flex flex-row">
                      <input
                        type="text"
                        value={formEmail}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                          setFormEmail(e.target.value);
                        }}
                        placeholder="Email Address"
                        className="flex-grow font-inter font-medium italic pl-1 border border-black py-1 text-black placeholder-black"
                        style={{ backgroundColor: '#A693DF' }}
                      />
                      <button
                        className={`text-white flex items-center px-4 bg-squirrellyPurple ${
                          !isEmail(formEmail) && 'cursor-not-allowed'
                        }`}
                        type="submit"
                        onClick={async (): Promise<void> => {
                          setEmailFooter(formEmail);
                          setFormSubmitted(true);
                        }}
                        disabled={!isEmail(formEmail)}
                      >
                        <BsArrowRight size={30} />
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotInWhiteListModal;
