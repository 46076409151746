/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactElement } from 'react';

import { contact } from '../../constants';

const SquirrelGrid = (): ReactElement => {
  const gridImageLocation = '/images/coverGrid/';
  const gridImage = [
    'A1Real',
    'B1Real',
    'C1Real',
    'A2Real',
    'B2Real',
    'C2Real',
    'A3Real',
    'B3Real',
    'C3Real',
  ];

  return (
    <div
      className="grid gap-1"
      style={{ gridTemplateColumns: 'repeat(3, minmax(10px, 300px))' }}
    >
      {gridImage.map((image, index) => {
        return (
          <img
            src={`${gridImageLocation}${image}.gif`}
            alt={`Squirrel ${index}`}
            key={image}
          />
        );
      })}
    </div>
  );
};

const Content = (): ReactElement => {
  return (
    <div>
      <img
        src="/images/logo/squirrellySquirrelsYellowTransparent.png"
        alt="Logo"
        className="mt-5 mb-5 mx-auto"
      />

      <p className="text-center text-white font-bangers mx-auto text-3xl mb-6">
        10,000 Squirrels out to rule the world
      </p>

      <div className="font-inter text-white text-center w-9/12 mx-auto mb-10 md:mb-4">
        <p className="mb-5 opacity-70">
          Unlock exclusive perks with{' '}
          <a
            href="https://www.squirrelwallet.com/"
            target="_blank"
            rel="noreferrer"
            className="font-bold underline"
          >
            Squirrel Wallet
          </a>{' '}
          when you have your own Squirrelly Squirrel! Join our{' '}
          <a
            href={contact.discordLink}
            target="_blank"
            rel="noreferrer"
            className="font-bold underline"
          >
            Discord
          </a>{' '}
          when you have your own Squirrelly Squirrel! Join our Discord and get
          smart about NFTs + DeFi.
        </p>
        <p className="opacity-70">Don’t be a normal squirrel.</p>
        <p>
          ✨ <span className="font-black">Be a Squirrelly Squirrel</span> ✨
        </p>
      </div>
    </div>
  );
};

export const Cover = (): ReactElement => {
  return (
    <div
      className="px-5 sm:px-10 pt-5 relative"
      style={{ backgroundImage: 'url("/images/stars/stars-cover.png")' }}
    >
      <div className="flex flex-col md:flex-row mt-5 justify-start max-h-full">
        <div className="order-2 md:order-1 mt-5 w-full md:w-7/12">
          <div className="w-full md:w-11/12 lg:w-9/12 mx-auto">
            <SquirrelGrid />
          </div>
        </div>

        <div className="flex flex-row items-center order-1 md:order-2 w-full md:w-5/12 lg:w-4/12 z-20">
          <Content />
        </div>
      </div>
    </div>
  );
};

export default Cover;
