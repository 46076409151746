import { ReactElement } from 'react';

import { AiFillInstagram } from 'react-icons/ai';

import parse from 'html-react-parser';

export const Artists = (): ReactElement => {
  return (
    <div
      className="bg-squirrellyYellow py-32"
      style={{
        borderTopLeftRadius: '64px',
        borderTopRightRadius: '64px',
        minHeight: '100vh',
        maxHeight: '100%',
      }}
      id="artists"
    >
      <div className="md:w-1/2 mx-auto px-5 md:px-0 my-auto">
        <p className="text-center font-grotesk font-black text-squirrellyPurple text-5xl md:text-7xl mb-5">
          THE ARTISTS
        </p>
        <p className="text-center font-bold text-squirrellyPurple text-2xl mb-14 opacity-70">
          Meet the mad minds behind our rad rodents
        </p>

        <div className="flex flex-col md:flex-row justify-between space-y-16 md:space-y-0">
          {artistsInfo.map((artist) => {
            return (
              <div
                className="flex flex-col mx-5 md:w-1/2"
                key={artist.artistName}
              >
                <img
                  src={artist.artistImage}
                  alt={artist.artistName}
                  className="mb-9 border-black rounded-2xl"
                  style={{ borderWidth: '6px' }}
                />

                <div className="flex flex-row justify-between mt-4 mb-4">
                  <p className="text-3xl text-squirrellyPurple font-bangers my-auto">
                    {artist.artistName}
                  </p>
                  <a
                    href={artist.instagramLink}
                    className="icon-instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AiFillInstagram
                      size={20}
                      className="text-squirrellyPurple"
                    />
                  </a>
                </div>
                <div className="font-bold text-squirrellyPurple opacity-70">
                  {parse(artist.artistDescription)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Artists;

const artistsInfo: {
  artistName: string;
  artistDescription: string;
  artistImage: string;
  instagramLink: string;
}[] = [
  {
    artistName: 'Jay Lintag',
    artistDescription: `
    <p className="mb-3">Jay uses vibrant colors and textures to make digital pieces that are fun, exaggerated, and upbeat.</p>
    <p>He woke up one day with a squid on his head and a burning desire to make 10,000 Squirrellies. What was life before the squid? There was no life before the squid.</p>
    `,
    artistImage: '/images/artist/Jay.png',
    instagramLink: 'https://www.instagram.com/jj.lintag/',
  },
  {
    artistName: 'Ross Chan',
    artistDescription: `
    <p className="mb-3">Ross thinks Jay’s been acting strange lately but is into these Squirrelly Squirrels! Constantly playing with colour, form, and storytelling, he gave shape to the Squirrelverse when most thought it was nuts.</p>
    <p>Ross loves drawing outside the lines, doodling, writing comics, and rubbing his cats' belly.</p>
    `,
    artistImage: '/images/artist/Ross.png',
    instagramLink: 'https://www.instagram.com/chanovel/',
  },
];
