import { useState, useMemo, useCallback } from 'react';

import Onboard from 'bnc-onboard';

import { ethers } from 'ethers'; // Ethers
import { Web3Provider } from '@ethersproject/providers';

import { createContainer } from 'unstated-next'; // Global state provider

import { setTncStatus, getTncStatus } from '../utils/firebase';

import { contact } from '../constants';

import { SquirrellySquirrels } from '../utils/squirrellySquirrels'

declare global {
  interface Window {
    ethereum: any;
  }
}

const useETH = (): {
  provider: any;
  address: string | null;
  tnc: boolean | null;
  isConnected: boolean;
  setAddress: (arg0: string | null) => void;
  unlock: () => void;
  signTnc: () => Promise<string>;
} => {
  const [address, setAddress] = useState<string | null>(null); // User address
  const [provider, setProvider] = useState<Web3Provider | null>(null); // Ethers provider
  const [tnc, setTnc] = useState<boolean | null>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const getChosenWallet = (): string | null => {
    return localStorage.getItem('chosenWallet');
  };

  const setChosenWallet = (chosenWallet: string | null): void => {
    if (chosenWallet) localStorage.setItem('chosenWallet', chosenWallet);
  };

  const removeChosenWallet = (): void => {
    localStorage.removeItem('chosenWallet');
  };

  const onboard = useMemo(() => {
    return Onboard({
      networkId: parseInt(process.env.REACT_APP_NETWORK as string, 10),
      hideBranding: true,
      walletSelect: {
        heading: 'Connect to Squirrelly Squirrells',
        description: 'Please select a wallet to authenticate.',
        wallets: [
          { walletName: 'metamask' },
          {
            walletName: 'walletConnect',
            rpc: {
              '1' : (process.env.REACT_APP_INFURA_MAINNET_ENDPOINT as string),
              '4': (process.env.REACT_APP_INFURA_RINKEBY_ENDPOINT as string),
            }
          },
        ],
      },
      subscriptions: {
        address: async (account: string): Promise<void> => {
          if (account === undefined) {
            setProvider(null);
            setAddress(null);
            setTnc(null);
            removeChosenWallet();
          } else {
            setAddress(account);
            await signTnc();
          }
        },
        wallet: async (wallet: any): Promise<void> => {
          if (wallet.provider) {
            const provider = new ethers.providers.Web3Provider(wallet.provider);

            setProvider(provider);
            setChosenWallet(wallet.name);

            SquirrellySquirrels.setInstance(provider);
          } else {
            setProvider(null);

            SquirrellySquirrels.setInstance(null);
          }
        },
      },
      walletCheck: [{ checkName: 'connect' }, { checkName: 'accounts' }, { checkName: 'network' }],
    });
  }, []);

  const unlock = useCallback(async () => {
    const chosenWallet = getChosenWallet();
    await onboard.walletSelect(chosenWallet ?? undefined);

    try {
      setIsConnected(await onboard.walletCheck());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [onboard]);

  const signTnc = async (): Promise<string> => {
    let signer = new ethers.providers.Web3Provider(
      window.ethereum,
      'any'
    )!.getSigner();

    let signature = '';

    const tncStatus = await getTncStatus(await signer.getAddress());
    setTnc(tncStatus);

    if (tncStatus !== true) {
      try {
        signature = await signer.signMessage(`Welcome to Squirrelly Squirrels

        Click to sign in and accept the Squirrelly Terms of Service: ${contact.tncLink}

        This request will not trigger a blockchain transaction or cost any gas fees.

        Wallet address: ${await signer.getAddress()}
        `);
        setAddress(await signer.getAddress());
        setTnc(true);

        await setTncStatus(signature, await signer.getAddress());
      } catch (err) {
        setAddress(null);
        setTnc(false);
      }
    }

    return signature;
  };

  return {
    provider,
    address,
    tnc,
    isConnected,
    setAddress,
    unlock,
    signTnc,
  };
};

const eth = createContainer(useETH);
export default eth;
