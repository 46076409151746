import { ReactElement, useEffect, useState } from 'react';

import { useLocation } from 'react-router';

import { ImagePickerContainer, FinalImage } from '../components/Generator';
import { Navbar } from '../components/Common';

import { useWindowSize } from '../hooks/useWindowSize';

import {
  listOfClothes,
  listOfMouths,
  listOfHats,
  listOfAccessories,
  hatsExclusions,
  accessoriesExclusions,
} from '../constants';

export const Generator = (): ReactElement => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [backgroudLocation, setBackgroudLocation] = useState(0);
  const baseBackgroundImageLocation = '/images/generator/layers/Backgrounds';

  const [skinLocation, setSkinLocation] = useState(0);
  const baseSkinImageLocation = '/images/generator/layers/Skins';

  const [eyeLocation, setEyeLocation] = useState(0);
  const baseEyeImageLocation = '/images/generator/layers/Eyes';

  const [clothesLocation, setClothesLocation] = useState(0);
  const baseClothesImageLocation = '/images/generator/layers/Clothes';

  const [glovesLocation, setGlovesLocation] = useState(0);
  const baseGlovesImageLocation = '/images/generator/layers/Gloves';

  const [mouthsLocation, setMouthsLocation] = useState(0);
  const baseMouthsImageLocation = '/images/generator/layers/Mouths';

  const [hatsLocation, setHatsLocation] = useState(0);
  const baseHatsImageLocation = '/images/generator/layers/Hats';

  const [accessoriesLocation, setAccessoriesLocation] = useState(0);
  const baseAccessoriesImageLocation = '/images/generator/layers/Accessories';

  const [arcadeImage, setArcadeImage] = useState(
    '/images/generator/arcadeMachine/initial.png'
  );

  const finalImageInitial: IFinalImage = {
    baseBackgroundImageLocation: '',
    backgroudLocation: 0,
    baseSkinImageLocation: '',
    skinLocation: 0,
    baseEyeImageLocation: '',
    eyeLocation: 0,
    baseMouthsImageLocation: '',
    mouthsLocation: 0,
    baseClothesImageLocation: '',
    clothesLocation: 0,
    baseAccessoriesImageLocation: '',
    accessoriesLocation: 0,
    baseHatsImageLocation: '',
    hatsLocation: 0,
    baseGlovesImageLocation: '',
    glovesLocation: 0,
  };
  const [finalImage, setFinalImage] = useState<IFinalImage>(finalImageInitial);

  const imageConflictsWithExclusionList = (): boolean => {
    const currentHat = listOfHats[finalImage.hatsLocation];
    const currentAccessory = listOfAccessories[finalImage.accessoriesLocation];
    const currentCloth = listOfClothes[finalImage.clothesLocation];
    const currentMouth = listOfMouths[finalImage.mouthsLocation];

    if (currentHat in hatsExclusions) {
      if ('Clothes' in hatsExclusions[currentHat]) {
        if (hatsExclusions[currentHat]['Clothes'].includes(currentCloth)) {
          return true;
        }
      } else if ('Accessories' in hatsExclusions[currentHat]) {
        if (hatsExclusions[currentHat]['Accessories'].includes(currentHat)) {
          return true;
        }
      } else if ('Mouths' in hatsExclusions[currentHat]) {
        if (hatsExclusions[currentHat]['Mouths'].includes(currentMouth)) {
          return true;
        }
      }
    }

    if (currentAccessory in accessoriesExclusions) {
      if ('Hats' in accessoriesExclusions[currentAccessory]) {
        if (
          accessoriesExclusions[currentAccessory]['Hats'].includes(currentHat)
        ) {
          return true;
        }
      } else if ('Mouths' in accessoriesExclusions[currentAccessory]) {
        if (
          accessoriesExclusions[currentAccessory]['Mouths'].includes(
            currentMouth
          )
        ) {
          return true;
        }
      } else if ('Clothes' in accessoriesExclusions[currentAccessory]) {
        if (
          accessoriesExclusions[currentAccessory]['Clothes'].includes(
            currentCloth
          )
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const { screenWidth } = useWindowSize();
  let arcadeMachineWidth = screenWidth <= 701 ? '350px' : '700px';

  return (
    <main
      className="flex flex-col pb-10 flex-grow"
      style={{ backgroundColor: '#4e26be' }}
    >
      <header className="sticky top-0 z-40 px-5 sm:px-10 pt-3">
        <Navbar />
      </header>

      <p className="font-grotesk text-4xl md:text-6xl text-white text-center mb-10">
        SQUIRREL-O-MATIC
      </p>

      <div className="bg-white border-4 border-black mx-auto font-bangers text-xl px-10 py-5 w-9/12 md:w-5/12 mb-10">
        PLAY AROUND WITH THE COLLECTION TO LEARN ABOUT THE DIFFERENT RARITIES.
        PRESS GO ON THE GENERATOR TO BUILD THE SQUIRREL YOU SELECTED!
      </div>

      <div className="flex flex-row flex-wrap md:w-11/12 mx-auto justify-center">
        <div>
          <ImagePickerContainer
            backgroudLocation={backgroudLocation}
            setBackgroudLocation={setBackgroudLocation}
            baseBackgroundImageLocation={baseBackgroundImageLocation}
            skinLocation={skinLocation}
            setSkinLocation={setSkinLocation}
            baseSkinImageLocation={baseSkinImageLocation}
            eyeLocation={eyeLocation}
            setEyeLocation={setEyeLocation}
            baseEyeImageLocation={baseEyeImageLocation}
            clothesLocation={clothesLocation}
            setClothesLocation={setClothesLocation}
            baseClothesImageLocation={baseClothesImageLocation}
            glovesLocation={glovesLocation}
            setGlovesLocation={setGlovesLocation}
            baseGlovesImageLocation={baseGlovesImageLocation}
            mouthsLocation={mouthsLocation}
            setMouthsLocation={setMouthsLocation}
            baseMouthsImageLocation={baseMouthsImageLocation}
            hatsLocation={hatsLocation}
            setHatsLocation={setHatsLocation}
            baseHatsImageLocation={baseHatsImageLocation}
            accessoriesLocation={accessoriesLocation}
            setAccessoriesLocation={setAccessoriesLocation}
            baseAccessoriesImageLocation={baseAccessoriesImageLocation}
          />
        </div>

        <div>
          <div
            className="relative"
            style={{
              width: arcadeMachineWidth,
              height: screenWidth <= 701 ? '400px' : '850px',
            }}
            onMouseOver={(): void => {
              setArcadeImage('/images/generator/arcadeMachine/hover.png');
            }}
            onMouseOut={(): void => {
              setArcadeImage('/images/generator/arcadeMachine/initial.png');
            }}
            onMouseDown={(): void => {
              setArcadeImage('/images/generator/arcadeMachine/pressed.png');

              setTimeout(() => {
                setArcadeImage('/images/generator/arcadeMachine/initial.png');
                setFinalImage({
                  baseBackgroundImageLocation: baseBackgroundImageLocation,
                  backgroudLocation: backgroudLocation,
                  baseSkinImageLocation: baseSkinImageLocation,
                  skinLocation: skinLocation,
                  baseEyeImageLocation: baseEyeImageLocation,
                  eyeLocation: eyeLocation,
                  baseMouthsImageLocation: baseMouthsImageLocation,
                  mouthsLocation: mouthsLocation,
                  baseClothesImageLocation: baseClothesImageLocation,
                  clothesLocation: clothesLocation,
                  baseAccessoriesImageLocation: baseAccessoriesImageLocation,
                  accessoriesLocation: accessoriesLocation,
                  baseHatsImageLocation: baseHatsImageLocation,
                  hatsLocation: hatsLocation,
                  baseGlovesImageLocation: baseGlovesImageLocation,
                  glovesLocation: glovesLocation,
                });
              }, 500);
            }}
          >
            <div
              className={`${
                JSON.stringify(finalImage) ===
                  JSON.stringify(finalImageInitial) && 'invisible'
              }`}
              style={{ paddingTop: screenWidth < 701 ? '60px' : '110px' }}
            >
              <FinalImage
                className="mx-auto z-10 relative"
                baseBackgroundImageLocation={
                  finalImage.baseBackgroundImageLocation
                }
                backgroudLocation={finalImage.backgroudLocation}
                baseSkinImageLocation={finalImage.baseSkinImageLocation}
                skinLocation={finalImage.skinLocation}
                baseEyeImageLocation={finalImage.baseEyeImageLocation}
                eyeLocation={finalImage.eyeLocation}
                baseMouthsImageLocation={finalImage.baseMouthsImageLocation}
                mouthsLocation={finalImage.mouthsLocation}
                baseClothesImageLocation={finalImage.baseClothesImageLocation}
                clothesLocation={finalImage.clothesLocation}
                baseAccessoriesImageLocation={
                  finalImage.baseAccessoriesImageLocation
                }
                accessoriesLocation={finalImage.accessoriesLocation}
                baseHatsImageLocation={finalImage.baseHatsImageLocation}
                hatsLocation={finalImage.hatsLocation}
                baseGlovesImageLocation={finalImage.baseGlovesImageLocation}
                glovesLocation={finalImage.glovesLocation}
              />
            </div>

            <img
              src={arcadeImage}
              alt="arcade machine initial"
              className="absolute top-0"
            />
          </div>
          <p
            className={`${
              imageConflictsWithExclusionList() ? 'visible' : 'invisible'
            } font-inter text-white font-extrabold text-center relative z-10 mt-5 md:mt-0`}
          >
            We are not nuts for this look
          </p>
        </div>
      </div>
    </main>
  );
};

export default Generator;

interface IFinalImage {
  baseBackgroundImageLocation: string;
  backgroudLocation: number;
  baseSkinImageLocation: string;
  skinLocation: number;
  baseEyeImageLocation: string;
  eyeLocation: number;
  baseMouthsImageLocation: string;
  mouthsLocation: number;
  baseClothesImageLocation: string;
  clothesLocation: number;
  baseAccessoriesImageLocation: string;
  accessoriesLocation: number;
  baseHatsImageLocation: string;
  hatsLocation: number;
  baseGlovesImageLocation: string;
  glovesLocation: number;
}
