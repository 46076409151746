export const contact = {
  discordLink: 'https://discord.gg/squirrelly',
  twitterLink: 'https://twitter.com/squirrel_wallet',
  instagramLink: 'https://www.instagram.com/squirrel.wallet/',
  tncLink: 'https://squirrellysquirrels.com/terms',
  smartContractAddress: '0xef1e32b81f99b7e4c623bcd2c49f938c6c55bbc8',
  gitbookLink: 'https://squirrel-wallet.gitbook.io/squirrel/products/squirrelly-squirrels',
  openseaLink: 'https://opensea.io/collection/squirrellysquirrelsofficial'
}

export const pageUrls ={
  home: '/',
  tnc: '/terms',
  generator: '/generator',
  story: '/story',
  mint: '/mint',
  account: '/account',
  dropAnimation: '/dropAnimation',
  provenance: '/provenance'
}

export const screenWidthBreakpoint = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  doubleXl: 1536
};

export const listOfBackground = [
  'common_blue',
  'common_dark',
  'common_green',
  'common_orange',
  'common_pink',
  'common_purple',
  'common_yellow',
  'rare_blue',
  'rare_dark',
  'rare_green',
  'rare_orange',
  'rare_pink',
  'rare_purple',
  'rare_yellow',
  'uncommon_blue',
  'uncommon_dark',
  'uncommon_green',
  'uncommon_orange',
  'uncommon_pink',
  'uncommon_purple',
  'uncommon_yellow',
];

export const listOfSkin = [
  'brown',
  'black',
  'blue',
  'bronze',
  'bumblebee',
  'camo',
  'dalmation',
  'galaxy',
  'gray',
  'green',
  'leopard',
  'light_blue',
  'maroon',
  'multi_pink',
  'multi_red',
  'pika',
  'pink',
  'purple',
  'radioactive',
  'robot',
  'skunk',
  'tatted',
  'tiger',
  'white',
  'zebra',
  'zombie',
];

export const listOfEyes = [
  'plain',
  'angry',
  'beady',
  'blue_adorable',
  'blue_plain',
  'blue_robot',
  'brown_eyed_squirrel',
  'bug',
  'burn',
  'caffeinated',
  'crying',
  'dead',
  'determined',
  'dizzy',
  'eager',
  'glinting',
  'green_eyed_squirrel',
  'green_robot',
  'nuts',
  'pink_pretty',
  'pretty',
  'purple_adorable',
  'red_almond',
  'red_robot',
  'scar',
  'shocked',
  'side_eye',
  'sleepy',
  'smug',
  'yawning',
  'yellow_almond',
  'yellow_smug'
];

export const listOfClothes = [
  'null',
  'are_those_scrubs',
  'art_attack',
  'banana',
  'black_flightsuit',
  'black_t',
  'black_turtleneck',
  'black_workshirt',
  'blue_baseball',
  'blue_camo',
  'blue_longsleeve',
  'blue_ruffles',
  'bomber',
  'brown_turtleneck',
  'darkgreen_longsleeve',
  'dress_shirt',
  'gamer',
  'graphic_tank',
  'gray_hoodie',
  'gray_t',
  'green_camo',
  'knight',
  'lab_coat',
  'limegreen_longsleeve',
  'maroon_suit',
  'navy_suit',
  'orange_hoodie',
  'pink_bow',
  'pink_button_blouse',
  'pink_flightsuit',
  'pink_hoodie',
  'purple_acorn_t',
  'purple_ruffles',
  'purple_workshirt',
  'rangers_jacket',
  'red_baseball',
  'red_vneck',
  'rose',
  'royal_robes',
  'safari',
  'salary_squirrel',
  'school_uniform_f',
  'school_uniform_m',
  'shark',
  'shiver',
  'snowman',
  'taco',
  'tie_dye_hoodie',
  'tracksuit',
  'traveller',
  'white_acorn_t',
  'white_gi',
  'white_tank',
  'white_turtleneck',
  'yellow_button_blouse',
  'yellow_camo',
  'yellow_gi',
  'yellow_vneck',
];

export const listOfGloves = [
  'null',
  'banana',
  'coin',
  'console',
  'gnarly',
  'gun',
  'nut',
  'peace',
  'scheming',
];

export const listOfMouths = [
  'normal',
  'biting_lip',
  'black_beard',
  'bloody',
  'braces',
  'bubblegum',
  'crooked',
  'drool',
  'ew',
  'goatee',
  'gray_beard',
  'happy',
  'hippo',
  'jaw_drop',
  'robot',
  'sad',
  'smug',
  'springy_tongue',
  'surprised',
  'tiger',
  'tongue',
  'tusks',
  'whistle',
];

export const listOfHats = [
  'null',
  'a_plane_hat',
  'antenna',
  'aviator',
  'banana',
  'bee',
  'black_bucket_hat',
  'black_cap',
  'black_tophat',
  'blue_baseball',
  'blue_cap_backwards',
  'cannonball',
  'dragonborn',
  'fish',
  'floppy',
  'gray_bowler',
  'gray_cap_backwards',
  'gray_fur_hat',
  'green_camo',
  'green_fur_hat',
  'hitai_ate',
  'knight',
  'moon_dream',
  'navy_bowler',
  'orange_headband',
  'orange_ski_goggles',
  'pajama',
  'pink_baseball',
  'pink_bow',
  'pink_bucket_hat',
  'pink_ski_goggles',
  'propeller',
  'purple_beret',
  'purple_tophat',
  'pussyhat',
  'raid_helmet',
  'ranger',
  'red_beret',
  'red_camo',
  'red_flower_power',
  'red_tennis',
  'rockstar_hair',
  'royal_crown',
  'safari',
  'short_hair',
  'snowman',
  'sombrero',
  'squid',
  'squirrel_kings_hat',
  'the_brain',
  'train_engineer',
  'turban',
  'turquoise_headband',
  'ultra_magical_unicorn_hood',
  'unidentified_head_object',
  'white_beanie',
  'white_bucket_hat',
  'wow_such_hood',
  'wrap_hairband',
  'yellow_flower_power',
];

export const listOfAccessoriesBottom = new Set<string>([
  'airpods',
  'aviators',
  'bandaid',
  'clown_nose',
  'crumbs',
  'earrings',
  'eyepatch',
  'glasses',
  'green_gas_mask',
  'mask',
  'monocle',
  'nose_ring',
  'pink_gas_mask',
  'small_moustache',
  'snot',
  'stickers',
  'third_eye',
]);

export const listOfAccessoriesTop = new Set<string>([
  'angry',
  'big_moustache',
  'blush',
  'electricity',
  'exclamation',
  'goggles',
  'gold_stars',
  'hearts',
  'jason_mask',
  'joy',
  'lollipop',
  'music_notes',
  'sweat',
]);

export const listOfAccessories = ['null'].concat(
  Array.from(listOfAccessoriesTop),
  Array.from(listOfAccessoriesBottom)
);

export const hatsExclusions: any = {
  // following hats do not go with the list of clothes
  banana: {
    Clothes: [
      'gamer',
      'royal_robes',
      'shark',
      'knight',
      'rose',
      'pink_bow',
      'yellow_button_blouse',
      'pink_button_blouse',
      'are_those_scrubs',
      'school_uniform_f',
      'shark',
    ],
    Accessories: ['mask', 'pink_gas_mask', 'green_gas_mask'],
    Mouths: [
      'biting_lip',
      'black_beard',
      'bloddy',
      'braces',
      'bubblegum',
      'crooked',
      'drool',
      'ew',
      'goatee',
      'gray_beard',
      'happy',
      'hippo',
      'jaw_drop',
      'normal',
      'pink_lips',
      'robot',
      'sad',
      'smug',
      'springy_tongue',
      'surprised',
      'tiger',
      'tongue',
      'tusks',
    ],
  },
  orange_ski_goggles: {
    Mouths: [
      'biting_lip',
      'black_beard',
      'bloddy',
      'braces',
      'bubblegum',
      'crooked',
      'drool',
      'ew',
      'goatee',
      'gray_beard',
      'happy',
      'hippo',
      'jaw_drop',
      'normal',
      'pink_lips',
      'robot',
      'sad',
      'smug',
      'springy_tongue',
      'surprised',
      'tiger',
      'tongue',
      'tusks',
    ],
  },
  pink_ski_goggles: {
    Mouths: [
      'biting_lip',
      'black_beard',
      'bloddy',
      'braces',
      'bubblegum',
      'crooked',
      'drool',
      'ew',
      'goatee',
      'gray_beard',
      'happy',
      'hippo',
      'jaw_drop',
      'normal',
      'pink_lips',
      'robot',
      'sad',
      'smug',
      'springy_tongue',
      'surprised',
      'tiger',
      'tongue',
      'tusks',
    ],
  },
  // snowman does not go with any other clothes but snowman
  snowman: {
    Clothes: [
      'are_those_scrubs',
      'art_attack',
      'banana',
      'black_flightsuit',
      'black_t',
      'black_turtleneck',
      'black_workshirt',
      'blue_baseball',
      'blue_camo',
      'blue_longsleeve',
      'blue_ruffles',
      'bomber',
      'brown_turtleneck',
      'darkgreen_longsleeve',
      'dress_shirt',
      'gamer',
      'ghost',
      'graphic_tank',
      'gray_hoodie',
      'gray_t',
      'green_camo',
      'knight',
      'lab_coat',
      'limegreen_longsleeve',
      'maroon_suit',
      'navy_suit',
      'orange_hoodie',
      'pink_bow',
      'pink_button_blouse',
      'pink_flightsuit',
      'pink_hoodie',
      'purple_acorn_t',
      'purple_ruffles',
      'purple_workshirt',
      'rangers_jacket',
      'red_baseball',
      'red_vneck',
      'rose',
      'royal_robes',
      'safari',
      'salary_squirrel',
      'school_uniform_f',
      'school_uniform_m',
      'shark',
      'shiver',
      'taco',
      'tie_dye_hoodie',
      'tracksuit',
      'traveller',
      'white_acorn_t',
      'white_gi',
      'white_tank',
      'white_turtleneck',
      'yellow_button_blouse',
      'yellow_camo',
      'yellow_gi',
      'yellow_vneck',
      'ghost',
    ],
  },
  // mostly for ghosts
  black_cap: { Clothes: ['ghost'] },
  pink_bucket_hat: { Clothes: ['ghost'] },
  black_bucket_hat: { Clothes: ['ghost'] },
  white_bucket_hat: { Clothes: ['ghost'] },
  navy_bowler: { Clothes: ['ghost'] },
  gray_bowler: { Clothes: ['ghost'] },
  red_tennis: { Clothes: ['ghost'] },
  orange_headband: { Clothes: ['ghost'] },
  turquoise_headband: { Clothes: ['ghost'] },
  red_flower_power: { Clothes: ['ghost'] },
  yellow_flower_power: { Clothes: ['ghost'] },
  red_camo: { Clothes: ['ghost'] },
  green_camo: { Clothes: ['ghost'] },
  safari: { Clothes: ['ghost'] },
  purple_beret: { Clothes: ['ghost'] },
  red_beret: { Clothes: ['ghost'] },
  rockstar_hair: { Clothes: ['ghost'] },
  floppy: { Clothes: ['ghost'] },
  white_beanie: { Clothes: ['ghost'] },
  pussyhat: { Clothes: ['ghost'] },
  // End ghost
  raid_helmet: { Mouths: ['robot'] },
  // Premium hats with chin straps. Mouth over hat
  dragonborn: {
    Mouths: [
      'biting_lip',
      'black_beard',
      'bloddy',
      'braces',
      'bubblegum',
      'crooked',
      'drool',
      'ew',
      'goatee',
      'gray_beard',
      'happy',
      'hippo',
      'jaw_drop',
      'normal',
      'pink_lips',
      'robot',
      'sad',
      'smug',
      'springy_tongue',
      'surprised',
      'tiger',
      'tongue',
      'tusks',
    ],
  },
  ultra_magical_unicorn_hood: {
    Mouths: [
      'biting_lip',
      'black_beard',
      'bloddy',
      'braces',
      'bubblegum',
      'crooked',
      'drool',
      'ew',
      'goatee',
      'gray_beard',
      'happy',
      'hippo',
      'jaw_drop',
      'normal',
      'pink_lips',
      'robot',
      'sad',
      'smug',
      'springy_tongue',
      'surprised',
      'tiger',
      'tongue',
      'tusks',
    ],
  },
  wow_such_hood: {
    Mouths: [
      'biting_lip',
      'black_beard',
      'bloddy',
      'braces',
      'bubblegum',
      'crooked',
      'drool',
      'ew',
      'goatee',
      'gray_beard',
      'happy',
      'hippo',
      'jaw_drop',
      'normal',
      'pink_lips',
      'robot',
      'sad',
      'smug',
      'springy_tongue',
      'surprised',
      'tiger',
      'tongue',
      'tusks',
    ],
  },
};

export const accessoriesExclusions: any = {
  // following accessories do not go with list of hats
  earrings: {
    Hats: [
      'knight',
      'royal_crown',
      'orange_ski_goggles',
      'pink_ski_goggles',
      'raid_helmet',
      'sombrero',
      'train_engineer',
      'red_beret',
      'purple_beret',
      'snowman',
      'green_fur_hat',
      'gray_fur_hat',
      'pink_bow',
      'turban',
      'cannonball',
      'dragonborn',
      'ultra_magical_unicorn_hood',
      'unidentified_head_object',
      'wow_such_hood',
      'moon_dream',
    ],
  },
  lollipop: {
    Hats: [
      'raid_helmet',
      'pilot',
      'cannonball',
      'ultra_magical_unicorn_hood',
      'unidentified_head_object',
      'moon_dream',
    ],
  },
  jason_mask: {
    Hats: [
      'knight',
      'raid_helmet',
      'sombrero',
      'banana',
      'black_cap',
      'floppy',
      'blue_baseball',
      'pink_baseball',
      'red_tennis',
      'safari',
      'cannonball',
      'dragonborn',
      'ultra_magical_unicorn_hood',
      'unidentified_head_object',
      'wow_such_hood',
      'moon_dream',
    ],
    Mouths: ['bubblegum'],
  },
  '3d_glasses': {
    Hats: [
      'knight',
      'raid_helmet',
      'floppy',
      'pajama',
      'train_engineer',
      'black_tophat',
      'purple_tophat',
      'pink_bucket_hat',
      'black_bucket_hat',
      'white_bucket_hat',
      'blue_baseball',
      'pink_baseball',
      'snowman',
      'red_tennis',
      'green_fur_hat',
      'gray_fur_hat',
      'wrap_hairband',
      'turban',
      'banana',
      'cannonball',
      'dragonborn',
      'unidentified_head_object',
      'wow_such_hood',
      'ultra_magical_unicorn_hood',
      'moon_dream',
    ],
  },
  goggles: {
    Hats: [
      'knight',
      'raid_helmet',
      'banana',
      'black_cap',
      'pilot',
      'pajama',
      'train_engineer',
      'red_tennis',
      'green_fur_hat',
      'gray_fur_hat',
      'turban',
      'cannonball',
      'dragonborn',
      'ultra_magical_unicorn_hood',
      'unidentified_head_object',
      'wow_such_hood',
      'moon_dream',
    ],
  },
  // following accessories do not go with list of mouths
  big_moustache: {
    Mouths: ['black_beard', 'gray_beard', 'bubblegum'],
  },
  chains: {
    Clothes: [
      'ghost',
      'traveller',
      'gamer',
      'royal_robes',
      'shark',
      'rose',
      'tracksuit',
      'snowman',
      'rangers_jacket',
      'white_turtleneck',
      'brown_turtleneck',
      'dress_shirt',
      'art_attack',
      'salary_squirrel',
      'blue_camo',
      'green_camo',
      'yellow_camo',
      'safari',
      'black_workshirt',
      'purple_workshirt',
      'school_uniform_m',
    ],
    Hats: ['banana'],
    Mouths: [
      'black_beard',
      'bloody',
      'gray_beard',
      'happy',
      'hippo',
      'jaw_drop',
      'springy_tongue',
      'tusks',
      'bubblegum',
      'tiger',
      'robot',
    ],
  },
  // following accessories do not go with list of hats
  glasses: {
    Hats: [
      'raid_helmet',
      'blue_baseball',
      'pink_baseball',
      'cannonball',
      'ultra_magical_unicorn_hood',
      'wow_such_hood',
    ],
    Mouths: ['bubblegum'],
  },
  clown_nose: {
    Hats: ['raid_helmet', 'cannonball', 'moon_dream'],
    Mouths: ['bubblegum'],
  },
  airpods: {
    Hats: [
      'knight',
      'royal_crown',
      'orange_ski_goggles',
      'pink_ski_goggles',
      'raid_helmet',
      'sombrero',
      'banana',
      'pajama',
      'train_engineer',
      'red_beret',
      'purple_beret',
      'black_tophat',
      'purple_tophat',
      'snowman',
      'green_fur_hat',
      'gray_fur_hat',
      'pink_bow',
      'safari',
      'wrap_hairband',
      'turban',
      'pussyhat',
      'white_beanie',
      'cannonball',
      'dragonborn',
      'ultra_magical_unicorn_hood',
      'unidentified_head_object',
      'wow_such_hood',
      'moon_dream',
    ],
  },
  monocle: {
    Hats: [
      'raid_helmet',
      'cannonball',
      'ultra_magical_unicorn_hood',
      'unidentified_head_object',
      'wow_such_hood',
    ],
  },
  mask: {
    Hats: ['banana', 'ultra_magical_unicorn_hood', 'wow_such_hood'],
    Mouths: ['bubblegum'],
  },
  pink_gas_mask: {
    Hats: [
      'orange_ski_goggles',
      'pink_ski_goggles',
      'banana',
      'ultra_magical_unicorn_hood',
      'wow_such_hood',
      'moon_dream',
    ],
    Mouths: ['bubblegum'],
  },
  green_gas_mask: {
    Hats: [
      'orange_ski_goggles',
      'pink_ski_goggles',
      'banana',
      'ultra_magical_unicorn_hood',
      'wow_such_hood',
      'moon_dream',
    ],
    Mouths: ['bubblegum'],
  },
  eyepatch: {
    Hats: ['pajama'],
  },
  aviators: {
    Hats: [
      'knight',
      'banana',
      'floppy',
      'pajama',
      'red_beret',
      'purple_beret',
      'pink_bucket_hat',
      'black_bucket_hat',
      'white_bucket_hat',
      'blue_baseball',
      'pink_baseball',
      'red_tennis',
      'green_fur_hat',
      'gray_fur_hat',
      'turban',
      'cannonball',
      'ultra_magical_unicorn_hood',
      'unidentified_head_object',
      'wow_such_hood',
    ],
    Mouths: ['bubblegum'],
  },
  // following accessories do not go with list of mouths
  small_moustache: {
    Mouths: ['black_beard', 'gray_beard', 'bubblegum'],
  },
  snot: {
    Mouths: ['bubblegum'],
  },
  nose_ring: {
    Mouths: ['bubblegum'],
  },
  crumbs: {
    Mouths: ['bubblegum'],
  },
};
