import { ReactElement, useEffect } from 'react';

import { pageUrls } from '../constants';

import { useNavigate } from 'react-router-dom';

export const DropAnimation = (): ReactElement => {
  const navigate = useNavigate();

  const seenDropAnimation = (): void => {
    localStorage.setItem('dropAnimationSeen', 'true');
    navigate(pageUrls.account);
  };
  
  useEffect(() => {
    if (localStorage.getItem('dropAnimationSeen') === 'true') {
      navigate(pageUrls.account);
    }
  }, []);

  return (
    <div className="bg-black">
      <video
        autoPlay
        muted
        id="dropAnimation"
        className="h-screen mx-auto object-cover"
        onEnded={(): void => {
          setTimeout(() => {
            seenDropAnimation();
          }, 2000)
        }}
      >
        <source
          src="/images/dropAnimation/dropAnimation.mp4"
          type="video/mp4"
        />
      </video>

      <a
        href={pageUrls.account}
        className="right-0 bottom-0 absolute"
        onClick={(): void => {
          seenDropAnimation();
        }}
      >
        <img src="/images/dropAnimation/accountPage.png" alt="account page" />
      </a>
    </div>
  );
};

export default DropAnimation;
