import { ReactElement, useState, useEffect } from 'react';
import { LayerPicker } from './LayerPicker';

import { useWindowSize } from '../../hooks/useWindowSize';

import {
  listOfBackground,
  listOfSkin,
  listOfEyes,
  listOfClothes,
  listOfGloves,
  listOfMouths,
  listOfHats,
  listOfAccessories,
} from '../../constants';

export const ImagePickerContainer = ({
  className,
  backgroudLocation,
  setBackgroudLocation,
  baseBackgroundImageLocation,
  skinLocation,
  setSkinLocation,
  baseSkinImageLocation,
  eyeLocation,
  setEyeLocation,
  baseEyeImageLocation,
  clothesLocation,
  setClothesLocation,
  baseClothesImageLocation,
  glovesLocation,
  setGlovesLocation,
  baseGlovesImageLocation,
  mouthsLocation,
  setMouthsLocation,
  baseMouthsImageLocation,
  hatsLocation,
  setHatsLocation,
  baseHatsImageLocation,
  accessoriesLocation,
  setAccessoriesLocation,
  baseAccessoriesImageLocation,
}: {
  className?: string;
  backgroudLocation: number;
  setBackgroudLocation: React.Dispatch<React.SetStateAction<number>>;
  baseBackgroundImageLocation: string;
  skinLocation: number;
  setSkinLocation: React.Dispatch<React.SetStateAction<number>>;
  baseSkinImageLocation: string;
  eyeLocation: number;
  setEyeLocation: React.Dispatch<React.SetStateAction<number>>;
  baseEyeImageLocation: string;
  clothesLocation: number;
  setClothesLocation: React.Dispatch<React.SetStateAction<number>>;
  baseClothesImageLocation: string;
  glovesLocation: number;
  setGlovesLocation: React.Dispatch<React.SetStateAction<number>>;
  baseGlovesImageLocation: string;
  mouthsLocation: number;
  setMouthsLocation: React.Dispatch<React.SetStateAction<number>>;
  baseMouthsImageLocation: string;
  hatsLocation: number;
  setHatsLocation: React.Dispatch<React.SetStateAction<number>>;
  baseHatsImageLocation: string;
  accessoriesLocation: number;
  setAccessoriesLocation: React.Dispatch<React.SetStateAction<number>>;
  baseAccessoriesImageLocation: string;
}): ReactElement => {
  const layerPicker: ILayerPicker[] = [
    {
      label: 'BACKGROUND',
      key: 'background',
      layerNumber: backgroudLocation,
      setLayerNumber: setBackgroudLocation,
      listToIterate: listOfBackground,
      imageLocation: baseBackgroundImageLocation,
      type: 'backgrounds',
    },
    {
      label: 'FUR',
      key: 'fur',
      layerNumber: skinLocation,
      setLayerNumber: setSkinLocation,
      listToIterate: listOfSkin,
      imageLocation: baseSkinImageLocation,
      type: 'skins',
    },
    {
      label: 'EYES',
      key: 'eyes',
      layerNumber: eyeLocation,
      setLayerNumber: setEyeLocation,
      listToIterate: listOfEyes,
      imageLocation: baseEyeImageLocation,
      type: 'eyes',
    },
    {
      label: 'MOUTH',
      key: 'mouth',
      layerNumber: mouthsLocation,
      setLayerNumber: setMouthsLocation,
      listToIterate: listOfMouths,
      imageLocation: baseMouthsImageLocation,
      type: 'mouths',
    },
    {
      label: 'CLOTHING',
      key: 'clothing',
      layerNumber: clothesLocation,
      setLayerNumber: setClothesLocation,
      listToIterate: listOfClothes,
      imageLocation: baseClothesImageLocation,
      type: 'clothes',
    },
    {
      label: 'HAT',
      key: 'hat',
      layerNumber: hatsLocation,
      setLayerNumber: setHatsLocation,
      listToIterate: listOfHats,
      imageLocation: baseHatsImageLocation,
      type: 'other',
    },
    {
      label: 'ACCESSORIES',
      key: 'accessories',
      layerNumber: accessoriesLocation,
      setLayerNumber: setAccessoriesLocation,
      listToIterate: listOfAccessories,
      imageLocation: baseAccessoriesImageLocation,
      type: 'other',
    },
    {
      label: 'GLOVES',
      key: 'gloves',
      layerNumber: glovesLocation,
      setLayerNumber: setGlovesLocation,
      listToIterate: listOfGloves,
      imageLocation: baseGlovesImageLocation,
      type: 'other',
    },
  ];

  const [currentLayerInfo, setCurrentLayerInfo] = useState<ILayerPicker>({
    label: layerPicker[0].label,
    key: layerPicker[0].key,
    layerNumber: layerPicker[0].layerNumber,
    setLayerNumber: layerPicker[0].setLayerNumber,
    listToIterate: layerPicker[0].listToIterate,
    imageLocation: layerPicker[0].imageLocation,
    type: layerPicker[0].type,
  });

  useEffect(() => {
    let layerNumberLocal = 0;
    switch (currentLayerInfo.key) {
      case 'background': {
        layerNumberLocal = backgroudLocation;
        break;
      }
      case 'fur': {
        layerNumberLocal = skinLocation;
        break;
      }
      case 'eyes': {
        layerNumberLocal = eyeLocation;
        break;
      }
      case 'mouth': {
        layerNumberLocal = mouthsLocation;
        break;
      }
      case 'clothing': {
        layerNumberLocal = clothesLocation;
        break;
      }
      case 'hat': {
        layerNumberLocal = hatsLocation;
        break;
      }
      case 'accessories': {
        layerNumberLocal = accessoriesLocation;
        break;
      }
      case 'gloves': {
        layerNumberLocal = glovesLocation;
        break;
      }
    }

    setCurrentLayerInfo({
      label: currentLayerInfo.label,
      key: currentLayerInfo.key,
      layerNumber: layerNumberLocal,
      setLayerNumber: currentLayerInfo.setLayerNumber,
      listToIterate: currentLayerInfo.listToIterate,
      imageLocation: currentLayerInfo.imageLocation,
      type: currentLayerInfo.type,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessoriesLocation,
    backgroudLocation,
    clothesLocation,
    eyeLocation,
    glovesLocation,
    hatsLocation,
    mouthsLocation,
    skinLocation,
  ]);

  const { screenWidth } = useWindowSize();
  let layerWidth = screenWidth < 400 ? '150px': '200px';

  return (
    <div className={`${className} flex flex-col md:flex-row`}>
      <div className="order-2 md:order-1 flex flex-row md:flex-col flex-wrap justify-evenly md:mr-5">
        {layerPicker.map((layerInfo) => {
          return (
            <div
              className="mb-1"
              key={layerInfo.key}
              style={{ width: layerWidth }}
            >
              <button
                className={`${
                  currentLayerInfo.key === layerInfo.key
                    ? 'bg-yellow-300'
                    : 'bg-white'
                } border-4 border-black font-bangers text-2xl text-center py-2 text-squirrellyPurple w-full hover:bg-opacity-90`}
                onClick={(): void => {
                  setCurrentLayerInfo(layerInfo);
                }}
              >
                {layerInfo.label}
              </button>
            </div>
          );
        })}
      </div>

      <div className="order-1 md:order-2 mb-10 md:mb-0">
        <LayerPicker
          layerNumber={currentLayerInfo.layerNumber}
          setLayerNumber={currentLayerInfo.setLayerNumber}
          listToIterate={currentLayerInfo.listToIterate}
          imageLocation={currentLayerInfo.imageLocation}
          type={currentLayerInfo.type}
          accessoryBeingModified={currentLayerInfo.key}
        />
      </div>
    </div>
  );
};

export default ImagePickerContainer;

interface ILayerPicker {
  label: string;
  key: string;
  layerNumber: number;
  setLayerNumber: React.Dispatch<React.SetStateAction<number>>;
  listToIterate: string[];
  imageLocation: string;
  type: 'backgrounds' | 'skins' | 'eyes' | 'mouths' | 'clothes' | 'other';
}
