import { ReactElement, useState, useEffect } from 'react';

import { ImPlus } from 'react-icons/im';
import parse from 'html-react-parser';

import { contact } from '../../constants';

const FaqQna = (): ReactElement => {
  const [selectedFaq, setSelectedFaq] = useState(-1);

  return (
    <div className="space-y-5">
      {faq.map((faqEntry, index) => {
        return (
          <div
            className="bg-white mb-5 p-5 rounded-2xl cursor-pointer relative z-10"
            key={index}
            onClick={(): void => {
              if (selectedFaq === index) {
                setSelectedFaq(-1);
              } else {
                setSelectedFaq(index);
              }
            }}
          >
            <div className="flex flex-row justify-between">
              <p className="text-3xl text-squirrellyPurple font-bangers tracking-wider">
                {faqEntry.faq}
              </p>
              <div className="icon">
                <ImPlus
                  style={
                    selectedFaq === index ? { transform: 'rotate(45deg)' } : {}
                  }
                />
              </div>
            </div>
            <div className={`mt-5 ${selectedFaq !== index && 'hidden'}`}>
              {parse(faqEntry.faqAnswer)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const DiscordBanner = (): ReactElement => {
  const imageLocation = '/images/faq/';
  const imageNames = [
    'discord_banner_dark_blue.svg',
    'discord_banner_light_blue.svg',
  ];

  const [image, setImage] = useState('discord_banner_dark_blue.svg');

  useEffect(() => {
    const interval = setInterval(() => {
      if (image === imageNames[0]) {
        setImage(imageNames[1]);
      } else {
        setImage(imageNames[0]);
      }
    }, 500);

    return (): void => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <a href={contact.discordLink} target="_blank" rel="noreferrer">
      <img
        src={`${imageLocation}${image}`}
        alt="discord banner"
        className="w-full md:w-8/12 discordBanner"
      />
    </a>
  );
};

export const Faq = (): ReactElement => {
  return (
    <div className="relative" id="faq">
      <div className="w-full px-2 sm:px-0 sm:w-9/12 mx-auto lg:w-6/12 mb-5">
        <p className="text-center font-grotesk font-black text-squirrellyYellow text-5xl md:text-7xl mb-6">
          SQUIRREL FAQS
        </p>
        <p className="text-center text-squirrellyYellow text-lg md:text-xl mb-10">
          <span className="font-black opacity-70">
            Check out our most frequently asked questions
          </span>{' '}
          🐿️
        </p>

        <FaqQna />
      </div>

      <DiscordBanner />
    </div>
  );
};

export const faq: {
  faq: string;
  faqAnswer: string;
}[] = [
  {
    faq: 'What are Squirrels?',
    faqAnswer: `<div className="font-inter text-squirrellyPurple font-medium opacity-70">Squirrelly Squirrels are 10,000 unique NFTs generated from 300+ traits.
    No two are alike and they’re more than just art - they’re collectible pieces of friendly counterculture. When you get a Squirrel, you gain access to exclusive,
    members-only offerings and benefits. Check out our roadmap to see what’s stashed away for you!</div>`,
  },
  {
    faq: 'What are NFTS?',
    faqAnswer: `<div className="font-inter text-squirrellyPurple font-medium"><p className="mb-5"><span className="opacity-70">“Nut Foraging Tokens”— we recommend gathering as much as you can</span> 😛</p>
    <span className="opacity-70">
    We’re just being <span className="font-black">cheeky</span>. Non-fungible tokens (NFTs) are digital collectibles on the blockchain that are easier to trade, store, and show off than the physical version.
     Ours also acts as a membership pass to our community of DeFi enthusiasts. Get in the club!
     </span>
     </div>`,
  },
  {
    faq: 'Are some Squirrels rarer than others?',
    faqAnswer: `<div className="font-inter text-squirrellyPurple">
    <span className="font-medium opacity-70">Squirrels are randomly generated, but some traits are more rare
    than others. Once all Squirrels are in the market, you’ll be able to view their trait rarity through 3rd parties like Rarity Tools. Rarity scores may affect value -
    <span className="font-black">but the heart wants what the heart wants</span>. Get yourself a Squirrel that looks rare to you</span> 🥺
    </div>`,
  },
  {
    faq: 'How can I get a squirrelly squirrel?',
    faqAnswer: `<div className="font-inter text-squirrellyPurple font-medium opacity-70"><p className="mb-5">Sign up for our whitelist to get in on our presale on February 9, 2022,
    or join the public sale on February 10. You'll need an ETH-compatible wallet like Rainbow or Metamask.</p>
    More instructions will come as we get closer to our sale date. In the meantime, secure a spot on our whitelist to make sure your NFTs are <span className="font-black">minty fresh</span>.</div>`,
  },
  {
    faq: 'How much will the NFTs be?',
    faqAnswer: `<div className="font-inter text-squirrellyPurple font-medium opacity-70">Squirrellies will be 0.16 ETH each. Buy a bunch of Squirrel frens and get a discount:
    <ul className="list-disc ml-7 mt-2">
      <li>3 for 0.39 ETH</li>
      <li>5 for 0.45 ETH</li>
    </ul>
    </div>`,
  },
  {
    faq: 'When is the sale?',
    faqAnswer: `<div className="font-inter text-squirrellyPurple font-medium opacity-70">
    <p className="mb-5">10,000 Squirrelly Squirrels will be released in a public sale on <span className="font-black">February 10, 2022</span>.</p>
    <p><span className="font-black">Want to cut ahead of the line?</span> Join our discord and get yourself on the whitelist to buy your NFTs on February 9, 2022,
    24 hours before everyone else. There are only <span className="font-black">1,000 whitelist spots</span>, so make sure you claim yours!</p>
    </div>`,
  },
];

export default Faq;
