import { ReactElement, useEffect, useState } from 'react';

import { Navbar } from '../components/Common';
import {
  Cover,
  Welcome,
  Roadmap,
  Traits,
  Faq,
  Artists,
} from '../components/Home';
import {
  GetUpdatesModal,
} from '../components/Home/modal';

import { useLocation } from 'react-router';

export const Home = (): ReactElement => {
  const location = useLocation();

  const [showGetUpdatesModal, setShowGetUpdatesModal] = useState(false);

  useEffect(() => {
    if (location.hash === '') {
      window.scrollTo(0, 0);
    } else {
      document.getElementById(location.hash.slice(1))?.scrollIntoView();
    }
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem('updatesModalShown') !== 'true') {
      setTimeout(() => {
        setShowGetUpdatesModal(true);
        localStorage.setItem('updatesModalShown', 'true');
      }, 5000);
    }
  }, []);
  

  return (
    <main style={{ backgroundColor: '#4E26BE' }}>
      <header
        className="sticky top-0 z-40 px-5 sm:px-10 pt-3 bg-opacity-90"
        style={{
          backgroundImage: 'linear-gradient(#2F1982FF, #352C5600)',
          backgroundColor: '#4E26BEAA',
        }}
      >
        <Navbar />
      </header>

      <div className="flex flex-col" style={{ backgroundColor: '#4E26BE' }}>
        <GetUpdatesModal
          display={showGetUpdatesModal}
          setDisplay={setShowGetUpdatesModal}
        />

        <div
          className="pb-36 md:pb-64"
          style={{
            backgroundImage:
              'linear-gradient(180deg, #6755D600, #47309F73, #4E26BE99)',
          }}
        >
          <Cover />

          {/* <img src="/images/as_seen_on.png" alt="As seen on" className="mt-5" /> */}
        </div>

        <div
          style={{
            backgroundImage: 'linear-gradient(180deg, #4E26BE99, #8BD1F0FF)',
          }}
        >
          <div
            className=""
            style={{
              backgroundImage: 'url("/images/stars/stars-welcome.png")',
            }}
          >
            <Welcome />
          </div>
          <img
            src="/images/moonmap_top_half.png"
            alt=""
            className=" min-w-full"
          />
        </div>

        <Roadmap />

        <div
          style={{
            backgroundImage: 'linear-gradient(180deg, #8BD1F0FF, #4E26BE)',
            marginTop: '-35%',
          }}
        >
          <img
            src="/images/moonmap_bottom_half.png"
            alt=""
            className="min-w-full relative z-20"
          />
        </div>

        <div
          className="pb-96"
          style={{
            backgroundImage: 'url("/images/stars/stars-traits.png")',
            marginTop: '-9%',
          }}
        >
          <Traits className="pt-20 relative z-10" />
        </div>

        <div className="pb-80 md:pb-32 squirrelFaqDistance">
          <div className="flex justify-between">
            <img
              src="/images/faq/L2.png"
              alt=""
              style={{ width: '25%', height: '25%' }}
              className="hidden md:block"
            />
            <img
              src="/images/faq/R2.png"
              alt=""
              style={{ width: '15%', height: '15%' }}
              className="hidden md:block"
            />
          </div>

          <div className="faq-height">
            <Faq />
          </div>

          <div className="flex justify-between faq-bottom-spacing">
            <img
              src="/images/faq/L1.png"
              alt=""
              style={{ width: '20%', height: '20%', marginTop: '-20%' }}
              className="hidden md:block"
            />
            <img
              src="/images/faq/R1.png"
              alt=""
              style={{ width: '40%', height: '40%' }}
              className="hidden md:block"
            />
          </div>
        </div>

        <Artists />
      </div>
    </main>
  );
};

export default Home;
