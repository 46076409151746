import React, { ReactElement, useState } from 'react';

import { FaDiscord } from 'react-icons/fa';
import { BsTwitter, BsArrowRight } from 'react-icons/bs';
import { AiFillInstagram } from 'react-icons/ai';
import { SiGitbook } from 'react-icons/si';
import { AiOutlineCheck } from 'react-icons/ai';

import isEmail from 'validator/lib/isEmail';
import { setEmailFooter } from '../../utils/firebase';

import { contact, pageUrls } from '../../constants';

import { Link } from 'react-router-dom';

const FirstCol = ({ className }: { className?: string }): ReactElement => {
  const [formEmail, setFormEmail] = useState('');
  const [submitButtonIcon, setSubmitButtonIcon] = useState('right-arrow');

  return (
    <div className={`flex flex-col justify-between ${className}`}>
      <div className="mb-5 md:w-7/12 mx-auto md:mx-0">
        <p className="font-bangers text-white opacity-70 pb-1">
          BE IN THE KNOW!
        </p>

        <form
          onSubmit={(e: any): void => {
            e.preventDefault();
          }}
        >
          <div className="flex flex-row">
            <input
              type="text"
              value={formEmail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                setFormEmail(e.target.value);
              }}
              placeholder="Email Address"
              className="flex-grow text-white opacity-50 bg-white bg-opacity-10 font-inter font-medium italic text-xs py-1 pl-1"
            />
            <button
              className={`text-white flex items-center px-2 bg-white bg-opacity-10 ${
                !isEmail(formEmail) && 'cursor-not-allowed'
              }`}
              type="submit"
              onClick={async (): Promise<void> => {
                setEmailFooter(formEmail);
                setSubmitButtonIcon('checkmark');
                setFormEmail('');

                setTimeout(() => {
                  setSubmitButtonIcon('right-arrow');
                }, 2000)
              }}
              disabled={!isEmail(formEmail)}
            >
              {submitButtonIcon === 'right-arrow' ? (
                <BsArrowRight size={16} />
              ) : (
                <AiOutlineCheck size={16} />
              )}
            </button>
          </div>
        </form>
      </div>

      <div>
        <p className="break-all font-inter font-medium text-xs text-white opacity-70 text-center md:text-left">
          Verified smart contract:{' '}
          <a
            href={`https://etherscan.com/address/${contact.smartContractAddress}`}
            className="font-extrabold"
            target="_blank"
            rel="noreferrer"
          >
            {contact.smartContractAddress}
          </a>
        </p>
      </div>
    </div>
  );
};

const ThirdCol = ({ className }: { className?: string }): ReactElement => {
  return (
    <div className={`flex flex-col justify-between ${className}`}>
      <div className="flex flex-row justify-center md:justify-end">
        <div className="mr-3">
          <a
            href={contact.instagramLink}
            className="icon-footer"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillInstagram size={16} />
          </a>
        </div>
        <div className="mr-3">
          <a
            href={contact.twitterLink}
            className="icon-footer"
            style={{ padding: '5px' }}
            target="_blank"
            rel="noreferrer"
          >
            <BsTwitter size={14} />
          </a>
        </div>
        <div className="mr-3">
          <a
            href={contact.discordLink}
            className="icon-footer"
            target="_blank"
            rel="noreferrer"
          >
            <FaDiscord size={16} />
          </a>
        </div>
        <div>
          <a
            href={contact.gitbookLink}
            className="icon-footer"
            target="_blank"
            rel="noreferrer"
          >
            <SiGitbook size={16} />
          </a>
        </div>
      </div>

      <div className="text-center md:text-right">
        <Link
          to={pageUrls.tnc}
          className="underline font-inter font-medium text-xs opacity-70 text-white"
        >
          Terms and Conditions
        </Link>

        <p className="font-inter font-extrabold text-xs text-white opacity-70">
          © 2022 Squirrel
        </p>
      </div>
    </div>
  );
};

export const Footer = (): ReactElement => {
  return (
    <div className="bg-squirrellyPurple flex flex-col md:flex-row justify-between p-10">
      <FirstCol className="w-full md:w-5/12 order-2 md:order-1 md:pr-10 py-10 md:py-0" />

      <a className="w-9/12 md:w-2/12 order-1 md:order-2 mx-auto" href={pageUrls.home}>
        <img
          src="/images/logo/squirrellySquirrelsYellowTransparent.png"
          alt="Logo"
          className=""
        />
      </a>

      <ThirdCol className="w-full md:w-5/12 order-3" />
    </div>
  );
};

export default Footer;
