import { ReactElement } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';

import {
  listOfBackground,
  listOfSkin,
  listOfEyes,
  listOfClothes,
  listOfGloves,
  listOfMouths,
  listOfHats,
  listOfAccessories,
  listOfAccessoriesBottom,
  listOfAccessoriesTop,
} from '../../constants';

export const FinalImage = ({
  className,
  baseBackgroundImageLocation,
  backgroudLocation,
  baseSkinImageLocation,
  skinLocation,
  baseEyeImageLocation,
  eyeLocation,
  baseMouthsImageLocation,
  mouthsLocation,
  baseClothesImageLocation,
  clothesLocation,
  baseAccessoriesImageLocation,
  accessoriesLocation,
  baseHatsImageLocation,
  hatsLocation,
  baseGlovesImageLocation,
  glovesLocation,
}: {
  className?: string;
  baseBackgroundImageLocation: string;
  backgroudLocation: number;
  baseSkinImageLocation: string;
  skinLocation: number;
  baseEyeImageLocation: string;
  eyeLocation: number;
  baseMouthsImageLocation: string;
  mouthsLocation: number;
  baseClothesImageLocation: string;
  clothesLocation: number;
  baseAccessoriesImageLocation: string;
  accessoriesLocation: number;
  baseHatsImageLocation: string;
  hatsLocation: number;
  baseGlovesImageLocation: string;
  glovesLocation: number;
}): ReactElement => {
  const { screenWidth } = useWindowSize();

  const imageHeight = screenWidth < 701 ? '170px' : '350px';
  const imageWidth = screenWidth < 701 ? '170px' : '350px';

  return (
    <div
      className={`${className}`}
      style={{ height: imageHeight, width: imageWidth }}
    >
      <img
        src={`${baseBackgroundImageLocation}/${listOfBackground[backgroudLocation]}.png`}
        alt="background"
        className="absolute rounded-2xl"
        style={{ height: imageHeight, width: imageWidth }}
      />
      <img
        src={`${baseSkinImageLocation}/${listOfSkin[skinLocation]}.png`}
        alt="skin"
        className="absolute"
        style={{ height: imageHeight, width: imageWidth }}
      />
      <img
        src={`${baseEyeImageLocation}/${listOfEyes[eyeLocation]}.png`}
        alt="eyes"
        className="absolute"
        style={{ height: imageHeight, width: imageWidth }}
      />
      <img
        src={`${baseClothesImageLocation}/${listOfClothes[clothesLocation]}.png`}
        alt="clothes"
        className="absolute"
        style={{ height: imageHeight, width: imageWidth }}
      />
      <img
        src={`${baseMouthsImageLocation}/${listOfMouths[mouthsLocation]}.png`}
        alt="mouths"
        className="absolute"
        style={{ height: imageHeight, width: imageWidth }}
      />
      {listOfAccessoriesBottom.has(listOfAccessories[accessoriesLocation]) && (
        <img
          src={`${baseAccessoriesImageLocation}/${listOfAccessories[accessoriesLocation]}.png`}
          alt="accessoriesTop"
          className="absolute"
          style={{ height: imageHeight, width: imageWidth }}
        />
      )}
      <img
        src={`${baseHatsImageLocation}/${listOfHats[hatsLocation]}.png`}
        alt="hats"
        className="absolute"
        style={{ height: imageHeight, width: imageWidth }}
      />
      {listOfAccessoriesTop.has(listOfAccessories[accessoriesLocation]) && (
        <img
          src={`${baseAccessoriesImageLocation}/${listOfAccessories[accessoriesLocation]}.png`}
          alt="accessoriesTop"
          className="absolute"
          style={{ height: imageHeight, width: imageWidth }}
        />
      )}
      <img
        src={`${baseGlovesImageLocation}/${listOfGloves[glovesLocation]}.png`}
        alt="gloves"
        className="absolute"
        style={{ height: imageHeight, width: imageWidth }}
      />
    </div>
  );
};

export default FinalImage;
