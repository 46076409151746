export const setTncStatus = async(signature: string, address: string): Promise<void> => {
  await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_ADDRESSES}setTncStatus?address=${address}&signature=${signature}`)
};

export const getTncStatus = async(address: string): Promise<boolean | null> => {
  const data = await (await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_ADDRESSES}getTncStatus?address=${address}`)).text()

  return (data === 'false' || data === '') ? null : true;
}

export const setEmail = async(email: string, address: string): Promise<void> => {
  await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_ADDRESSES}setEmail?address=${address}&email=${email}`);
};

export const setEmailFooter = async(email: string): Promise<void> => {
  await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_ADDRESSES}setEmailFooter?email=${email}`);
}

export const getProof = async(address: string): Promise<string[]> => {
  const data = await(await fetch(`${process.env.REACT_APP_CLOUD_FUNCTION_ADDRESSES}getProof?address=${address}`)).json();
  return data.proof;
}