import { ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router';

import { Navbar } from '../components/Common';
import { FamousSquirrels } from '../components/Story';
import { contact, screenWidthBreakpoint } from '../constants';

import { useWindowSize } from '../hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';

export const Story = (): ReactElement => {
  const navigate = useNavigate();

  const location = useLocation();
  const { screenWidth } = useWindowSize();

  const version: 'desktop' | 'mobile' =
    screenWidth >= screenWidthBreakpoint.md ? 'desktop' : 'mobile';

  useEffect(() => {
    if (location.hash === '') {
      window.scrollTo(0, 0);
    } else {
      document.getElementById(location.hash[1])?.scrollIntoView();
    }
  }, [location]);

  return (
    <main
      style={{
        backgroundImage: 'url("/images/story/desktop/background.png")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: version === 'mobile' ? '' : 'cover',
        backgroundPositionX: 'center',
        backgroundColor: '#856427',
      }}
    >
      <header className="sticky top-0 z-40 px-5 sm:px-10 pt-3">
        <Navbar />
      </header>
      <img
        src={`/images/story/${version}/comic.png`}
        alt="comic"
        className="mx-auto mt-14"
      />

      <div className="pt-60 pb-32 md:pb-96">
        <FamousSquirrels />
      </div>

      <div className="relative">
        <a
          href={contact.discordLink}
          target="_blank"
          rel="noreferrer"
          className="absolute top-0 bottom-0"
          style={{ right: `${version === 'mobile' ? '0%' : '10%'}` }}
        >
          <img src="/images/story/discord_button.png" alt="whitelist button" />
        </a>

        <img
          src={`/images/story/${version}/clouds.png`}
          alt="clouds"
          style={{ objectFit: 'cover', width: '100%' }}
        />
      </div>
    </main>
  );
};

export default Story;
