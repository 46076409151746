import { ReactElement, useEffect } from 'react';

import { useLocation } from 'react-router';

import { Navbar } from '../components/Common/Navbar';
import { contact } from '../constants';

import { useQuery } from 'react-query';

const ImportantInformationTable = (): ReactElement => {
  const FINALIZED_STARTING_STRING = 903;
  const FINAL_PROOF_HASH =
    '5bbd3343bec4b44e0041e18dc8c61d0abce4011b84e5cb1a4d7e8c3bdd263df6';
  const FINAL_PROOF_HASH_LINK = '/data/provenance/finalProofHash.json';

  return (
    <table
      className="mx-auto border-separate"
      style={{ borderSpacing: '0px 20px' }}
    >
      <tbody>
        <tr>
          <td className="text-right">FINALIZED STARTING STRING</td>
          <td className="px-1">|</td>
          <td>{FINALIZED_STARTING_STRING}</td>
        </tr>
        <tr>
          <td className="text-right">SQUIRRELLY SQUIRRELS CONTRACT ADDRESS</td>
          <td className="px-1">|</td>
          <td>
            <a
              href={`https://etherscan.io/address/${contact.smartContractAddress}`}
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-90 underline break-all"
            >
              {contact.smartContractAddress}
            </a>
          </td>
        </tr>
        <tr>
          <td className="text-right">FINAL PROOF HASH</td>
          <td className="px-1">|</td>
          <td>
            <a
              href={FINAL_PROOF_HASH_LINK}
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-90 underline break-all"
            >
              {FINAL_PROOF_HASH}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const ProvenanceRecordTable = (): ReactElement => {
  const {
    data: provenanceRecordTable,
    isLoading: provenanceRecordTableLoading,
  } = useQuery(
    'provenanceRecordTable',
    async () => {
      const data = await fetch('/data/provenance/provenanceRecord.json', {
        method: 'GET',
        redirect: 'follow',
      });
      return await data.json();
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div>
      <table
        className="mx-auto border-separate overflow-x-scroll block"
        style={{ borderSpacing: '0px 5px' }}
      >
        <thead>
          <td className="font-semibold">INITIAL SEQUENCE INDEX</td>
          <td className="px-1">|</td>
          <td className="font-semibold">ASSIGNED SQRLY TOKEN ID</td>
          <td className="px-1">|</td>
          <td className="font-semibold">SHA-256 HASH</td>
          <td className="px-1">|</td>
          <td className="font-semibold">IMAGE LOCATION</td>
        </thead>
        {provenanceRecordTableLoading || (
          <tbody>
            {provenanceRecordTable.map(
              (provenanceRecord: {
                initialSequenceIndex: string;
                assignedSqrlyTokenId: string;
                sha256: string;
              }) => {
                return (
                  <tr key={provenanceRecord.assignedSqrlyTokenId}>
                    <td>{provenanceRecord.initialSequenceIndex}</td>
                    <td className="px-1">|</td>
                    <td>{provenanceRecord.assignedSqrlyTokenId}</td>
                    <td className="px-1">|</td>
                    <td>{provenanceRecord.sha256}</td>
                    <td className="px-1">|</td>
                    <td>
                      <a href={`https://squirrel.mypinata.cloud/ipfs/QmYY1cNScsToYha84j6MZN59sPQcdyxdhchFuDbhuyBh9A/${provenanceRecord.assignedSqrlyTokenId}.png`} target="_blank" rel="noopener noreferrer">
                        Ipfs location
                      </a>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        )}
      </table>
      {provenanceRecordTableLoading && (
        <p className="text-center">LOADING...</p>
      )}
    </div>
  );
};

export const Provenance = (): ReactElement => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const {
    data: concatenatedHashString,
    isLoading: concatenatedHashStringLoading,
  } = useQuery(
    'concatenatedHashString',
    async () => {
      const data = await fetch('/data/provenance/concatenatedHashString.txt', {
        method: 'GET',
        redirect: 'follow',
      });
      return await data.text();
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <main
      className="flex flex-col pb-10 flex-grow"
      style={{ backgroundColor: '#4e26be' }}
    >
      <header className="sticky top-0 z-40 px-5 sm:px-10 pt-3">
        <Navbar />
      </header>

      <div className="w-full my-5">
        <div className="w-11/12 md:w-8/12 mx-auto">
          <div className="text-white">
            <p className="font-bangers text-4xl mb-3">
              SQUIRRELLY SQUIRRELS PROVENANCE RECORD
            </p>
            <p>
              This page presents the provenance record of each Squirrelly
              Squirres that will ever exist. Each Squirrelly Squirrel image is
              firstly hashed using SHA-256 algorithm. A combined string is
              obtained by concatenating SHA-256 of each Squirrelly Squirrel
              image in the specific order as listed below. The final proof is
              obtained by SHA-256 hashing this combined string. This is the
              final provenance record stored on the smart contract.
            </p>
          </div>

          <hr className="my-10" />

          <div className="text-white">
            <p className="font-bangers text-2xl mb-3">IMPORTANT INFORMATION</p>
            <p className="mb-3">
              Each Squirrelly Squirrels token ID is assigned to an artwork image
              from the initial sequence with this formula:
            </p>
            <p className="text-center font-semibold mb-10">
              (tokenId + startingIndex) % 10000 → Initial Sequence Index
            </p>

            <p className="mb-3">Here's the relevant information:</p>
            <div className="mb-3">
              <ImportantInformationTable />
            </div>
          </div>

          <hr className="my-10" />

          <div className="text-white">
            <p className="font-bangers text-4xl mb-3">
              CONCATENATED HASH STRING
            </p>

            <p className="overflow-y-scroll overflow-x-hidden h-32 break-all">
              {concatenatedHashStringLoading
                ? 'LOADING...'
                : concatenatedHashString}
            </p>
          </div>

          <hr className="my-10" />

          <div className="text-white">
            <p className="font-bangers text-4xl mb-3">PROVENANCE RECORD</p>

            <p className="mb-3">
              The table below lists the original index, assigned SQRLY Token ID,
              SHA256 Hash output and IPFS link of each Squirrelly Squirrels
              image
            </p>

            <ProvenanceRecordTable />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Provenance;
