import { ReactElement } from 'react';

export const Traits = ({ className }: { className?: string }): ReactElement => {
  return (
    <div className={`${className}`}>
      <img
        src="/images/traits/banner.png"
        alt="traits banner"
        className="mx-auto mb-10 md:mb-20"
      />
      <img
        src="/images/traits/traitsGrouped.png"
        alt="traits grouped"
        className="mx-auto hidden md:block w-9/12"
      />

      {[1, 2, 3, 4, 5, 6, 7, 8].map((entry) => {
        return (
          <img
            src={`/images/traits/traits-${entry}.png`}
            alt={`traits-${entry}`}
            className="mx-auto block md:hidden pb-5 px-3"
            key={entry}
          />
        );
      })}

      <p className="text-white text-center w-full md:w-3/12 mx-auto text-lg pt-20 md:pt-0" style={{marginTop: '-8%'}}>
        Each pixel is painstakingly positioned by pro illustrators Jay Lintag
        and Ross Chan.
      </p>

      {/* <div className="flex justify-center items-center mt-20">
        <a
          href="https://app.gitbook.com/o/-Mhnl_M1zDoqNPCqNw56/s/-MhnleJ_usghAUyOavjV/c/1fGxwOJ5hpLS9f0lUuBv/products/squirrelly-squirrels/traits"
          target="_blank"
          rel="noreferrer"
          className="rounded-full px-10 py-3 self-center bg-white font-extrabold text-squirrellyPurple hover:bg-opacity-90"
        >
          Explore Squirrelly Traits
        </a>
      </div> */}
    </div>
  );
};

export default Traits;
