import { ReactElement } from 'react';

import {
  Home,
  Tnc,
  Generator,
  Story,
  Account,
  DropAnimation,
  Provenance,
} from './pages';
import { Footer } from './components/Common';

import { pageUrls } from './constants';

import { Routes, Route } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const App = (): ReactElement => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      <div className="flex flex-col min-h-screen">
        <Routes>
          <Route path={pageUrls.home} element={<Home />} />
          <Route path={pageUrls.tnc} element={<Tnc />} />
          <Route path={pageUrls.generator} element={<Generator />} />
          <Route path={pageUrls.story} element={<Story />} />
          <Route path={pageUrls.account} element={<Account />} />
          <Route path={pageUrls.dropAnimation} element={<DropAnimation />} />
          <Route path={pageUrls.provenance} element={<Provenance />} />
        </Routes>

        <footer>
          <Footer />
        </footer>
      </div>
    </QueryClientProvider>
  );
};

export default App;
